import validator from 'validator';
import { IDescription, ISettings } from '../../../types/shortlist';
import { validateEditorState } from '../../../components/RichTextEditor/utils/validator';

export const validateSetting = (data: ISettings) => {
    const errors = [];
    if (validator.isEmpty(data.id)) {
        const error = {
            name: 'id',
            error: 'id is missing',
        };
        errors.push(error);
    }
    if (validator.isEmpty(data.organization)) {
        const error = {
            name: 'organization',
            error: 'organisations is missing',
        };
        errors.push(error);
    }
    if (typeof data.owner === 'string' || data.owner instanceof String) {
        if (validator.isEmpty(data.owner as string)) {
            const error = {
                name: 'owner',
                error: 'Owner is missing',
            };
            errors.push(error);
        }
    }
    if (validator.isEmpty(data.createdDate)) {
        const error = {
            name: 'createdDate',
            error: 'createdDate is missing',
        };
        errors.push(error);
    }
    if (validator.isEmpty(JSON.stringify(data.status))) {
        const error = {
            name: 'status',
            error: 'status is missing',
        };
        errors.push(error);
    }
    return { status: errors.length > 0, errors };
};

export const validateDescription = (settings: ISettings, data: IDescription) => {
    const errors: any[] = [];
    if (!settings || !data) {
        return { status: errors.length > 0, errors };
    }
    if (settings.status === 1 || settings.status === 2) {
        if (validator.isEmpty(data?.title)) {
            const error = {
                name: 'title',
                error: 'title is missing',
            };
            errors.push(error);
        }
    }
    if (settings.status === 3 || settings.status === 4) {
        if (validator.isEmpty(data?.title)) {
            const error = {
                name: 'title',
                error: 'title is missing',
            };
            errors.push(error);
        }

        if (validator.isEmpty(data?.country)) {
            const error = {
                name: 'country',
                error: 'Country is missing',
            };
            errors.push(error);
        }
        if (validator.isEmpty(data?.city)) {
            const error = {
                name: 'city',
                error: 'city is missing',
            };
            errors.push(error);
        }
        // if (validator.isEmpty(data.postcode)) {
        //     const error = {
        //         name: 'postcode',
        //         error: 'postcode is missing',
        //     };
        //     errors.push(error);
        // }
        // if (validator.isEmpty(data?.startDate) || data.startDate === '' || data.startDate === 'Invalid Date') {
        //     const error = {
        //         name: 'startDate',
        //         error: 'startDate is missing',
        //     };
        //     errors.push(error);
        // }
        if (validator.isEmpty(data?.state)) {
            const error = {
                name: 'state',
                error: 'state is missing',
            };
            errors.push(error);
        }
        if (validateEditorState(data?.roleDescription?.editorState)) {
            const error = {
                name: 'roleDescription',
                error: 'roleDescription is missing',
            };
            errors.push(error);
        }
        if (validateEditorState(data?.keyRequirement?.editorState)) {
            const error = {
                name: 'keyRequirement',
                error: 'keyRequirement is missing',
            };
            errors.push(error);
        }
    }
    return { status: errors.length > 0, errors };
};
