import { useEffect, useState } from 'react';
import { groupBy } from '../utils/array';
import { Skill } from '../types/skill';

function useGetHighLevelSkills(skills: Skill[]) {
    const [levelOneSkills, setLevelOneSkills] = useState<any[]>([]);

    useEffect(() => {
        if (skills) {
            const oneSkills = skills.filter((skill) => (skill.parents || []).length === 0) as any[];
            const groupByType = groupBy(['type']);
            const groupedLevelOne = groupByType(oneSkills) as Record<string, Skill[]>;
            const levelOneSkill: any[] = [];
            Object.entries(groupedLevelOne).forEach(([key, value]) => {
                levelOneSkill.push({ group: key });
                value.forEach((item) => {
                    levelOneSkill.push(item);
                });
            });

            setLevelOneSkills(levelOneSkill);
        }
    }, [skills]);

    return { levelOneSkills };
}

export default useGetHighLevelSkills;
