import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { sendPing } from '../redux/actions/pingAction';
import { pingMessage } from '../data/pingMessage';

interface PingModalProps {
    open: boolean;
    userId: string;
    candidateName?: string;
    employerFirstName?: string;
    employerSurname?: string;
    organisationName?: string;
    employerEmail?: string;
    employerNumber?: string;
    readiness?: string;
    onClose: () => void;
}

const PingModal = ({
    open,
    userId,
    employerFirstName,
    employerSurname,
    organisationName,
    employerEmail,
    readiness,
    employerNumber,
    onClose,
}: PingModalProps) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState(pingMessage({ employerFirstName, employerSurname, employerEmail, employerNumber, organisationName }));

    const handleChange = async (event: any) => {
        const { name, value } = event.target;

        if (name === 'message') {
            setMessage(value);
        }
    };

    const submitPing = () => {
        dispatch(sendPing({ id: userId, message }));
        onClose();
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                PaperProps={{
                    sx: {
                        height: '60vh',
                    },
                }}
            >
                <DialogTitle>Send a message to this candidate</DialogTitle>

                <DialogContent style={{ height: '19.375rem' }}>
                    {/* TODO: Find a better way to align email template with backend */}
                    <br />
                    <Grid sx={{ flexGrow: 1 }} container spacing={4} alignContent="space-around">
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                fullWidth
                                value={message}
                                label="Message body (modify as necessary)"
                                name="message"
                                rows={12}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    {readiness && readiness === 'OPEN' && (
                        <Typography variant="grayText">
                            NOTE - After we have established a trusted connection by email or phone, please also click on this link to release your identity,
                            contact details and CV to myself and other job owners in {organisationName} within the platform.
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={() => submitPing()} disabled={false} variant="contained">
                        Send As Email
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PingModal;
