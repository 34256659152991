import {
    Typography,
    Link as StyledLink,
    Grid,
    Button,
    ButtonGroup,
    Chip,
    Box,
    Tooltip,
    IconButton,
    Dialog,
    DialogContent,
    TextField,
    Stack,
    Checkbox,
    DialogTitle,
    Drawer,
    DialogActions,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridExpandMoreIcon, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../../../../services/fetch';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganisations } from '../../../../redux/actions/organisationsAction';
import { selectOrganisationsData } from '../../../../redux/selectors/organisationsSelector';
import { ViewAsColumnJob } from '../../../../components/ViewAsColumnJob';
import SearchCriteriaModal from '../../../../components/modal/SearchCriteriaModal';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';

// wed 19
import { SearchMemberModifier, SearchRequest, SearchRequestProfile } from '../../../../types/search';
import { selectSearchCriteria } from '../../../../redux/selectors/searchSelector';
import { selectSkillsData } from '../../../../redux/selectors/skillsSelector';
import { clone } from 'ramda';
import { setSearchCriteria } from '../../../../redux/actions/searchAction';
import SkillModal from '../../../../components/filter/skills/SkillModal';
import { showErrorSnackbar, showInfoSnackbar } from '../../../../redux/actions/snackbarAction';
import { Skill } from '../../../../types/skill';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { skillFilter } from '../../CandidateSearchBeta';
import { auCitizenshipStatusDropdown, searchAttributeModifier } from '../../../../data/dropDownData';
import useGetHighLevelSkills from '../../../../hooks/useGetOneLevelSkills';
import dayjs from 'dayjs';

type DesiredSkill = Skill & { desirability?: SearchMemberModifier };
enum ViewAs {
    CARD = 'CARD',
    TABLE = 'TABLE',
}

const defaultSkillFilter: skillFilter = {
    MANDATORY: [],
    HIGHLY_DESIRABLE: [],
    DESIRABLE: [],
    NICE_TO_HAVE: [],
};

const JobBoard: React.FC = () => {
    const dispatch = useDispatch();
    const organizations = useSelector(selectOrganisationsData);

    // wed19
    const skills = useSelector(selectSkillsData);
    const searchCriteria = useSelector(selectSearchCriteria);
    const [skillModal, setSkillModal] = useState<boolean>(false);
    const [searchName, setSearchName] = useState('');
    const [showSavedJobs, setShowSavedJobs] = useState(false);
    const [savedSearches, setSavedSearches] = useState<{ searchName: string; createdAt: Date; id: number; data: any[]; skillFilter: skillFilter }[]>([]);
    const [openSearchModal, setOpenSearchModal] = useState(false);
    const [editSavedSearch, setEditSavedSearch] = useState('');

    const [jobData, setJobData] = useState<any[]>([]);

    const [desirability, setDesirability] = useState<SearchMemberModifier>('NICE_TO_HAVE');
    const [skillFilter, setSkillFilter] = useState<skillFilter>(defaultSkillFilter);
    const [filterData, setFilterData] = useState<SearchRequestProfile>({
        securityClearance: [],
        auCitizenshipStatus: [],
        aboriginalOrTorresStrait: false,
        disabled: false,
        genderFemale: false,
        genderNonBinary: false,
        veteranStatus: false,
        defencePartner: false,
        workforceReturner: false,
        seeking: [],
    } as SearchRequestProfile);

    function renderActions(params: any) {
        return (
            <>
                <IconButton
                    aria-label="Edit"
                    onClick={() => {
                        setEditSavedSearch(params.id as string);
                        setOpenSearchModal(true);
                        setSearchName(params.row.searchName as string);
                    }}
                >
                    <EditIcon />
                </IconButton>

                <IconButton aria-label="Delete">
                    <DeleteIcon onClick={() => deleteSavedSearch(params.id)} />
                </IconButton>
            </>
        );
    }

    function renderTitle(params: any) {
        return (
            <>
                <Typography
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                        setShowSavedJobs(true);
                        const data = savedSearches.find(({ id }) => id === params.id);
                        setJobData([...(data?.data ?? [])]);
                        setSkillFilter(data?.skillFilter ?? defaultSkillFilter);
                    }}
                >
                    <a>{params.row?.searchName}</a>
                </Typography>
            </>
        );
    }

    const column = [
        { field: 'searchName', headerName: 'Search Name', sortable: true, flex: 2, renderCell: renderTitle },
        {
            field: 'createdAt',
            headerName: 'Date Saved',
            sortable: true,
            flex: 2,
            renderCell: (params: any) => {
                return <Typography>{dayjs(params.row?.createdAt).format('YYYY-MM-DD')}</Typography>;
            },
        },
        { field: 'action', headerName: 'Action', sortable: true, flex: 1, renderCell: renderActions },
    ];
    useEffect(() => {
        const { profile, skills: searchSkills } = searchCriteria;
        if (profile) {
            let { auCitizenshipStatus, securityClearance } = profile;
            const {
                genderFemale = false,
                genderNonBinary = false,
                workforceReturner = false,
                veteranStatus = false,
                aboriginalOrTorresStrait = false,
                disabled = false,
                defencePartner = false,
                seeking,
            } = profile;
            if (!auCitizenshipStatus) {
                auCitizenshipStatus = auCitizenshipStatusDropdown.map((item) => item.value);
            }
            if (!securityClearance) {
                securityClearance = [];
            }
            setFilterData({
                auCitizenshipStatus,
                securityClearance,
                genderFemale,
                genderNonBinary,
                workforceReturner,
                veteranStatus,
                aboriginalOrTorresStrait,
                disabled,
                defencePartner,
                seeking,
            });
        }
        if (searchSkills) {
            const allSkills = Object.entries(searchSkills);
            const skillFilter = defaultSkillFilter;
            searchAttributeModifier.forEach((item) => {
                const result = [] as DesiredSkill[];
                allSkills.forEach(([id, values]) => {
                    if (item.value === values.modifier) {
                        const selectedSkill = skills.find((skill) => skill.id === id);
                        if (!selectedSkill) {
                            // handle if skill doesn't exist
                            return;
                        }
                        result.push({ ...selectedSkill, desirability: searchSkills[id].modifier } as DesiredSkill);
                    }
                });
                skillFilter[item.value as keyof skillFilter] = result;
            });
            setSkillFilter(skillFilter);
        } else {
            const defaultSkillFilter: skillFilter = {
                MANDATORY: [],
                HIGHLY_DESIRABLE: [],
                DESIRABLE: [],
                NICE_TO_HAVE: [],
            };
            setSkillFilter(defaultSkillFilter);
        }
    }, [skills, searchCriteria]);

    const fetchSavedResultFromLocalStorage = () => {
        const data = localStorage.getItem('jobBoard') as any;
        if (data) {
            const savedSearchesFromLocalStorage = JSON.parse(data);
            setSavedSearches(savedSearchesFromLocalStorage);
        }
    };

    useEffect(() => {
        fetchSavedResultFromLocalStorage();
    }, []);

    const handleSkillModalClose = () => {
        setSkillModal(false);
    };

    const [viewAs, setViewAs] = React.useState<ViewAs>(ViewAs.TABLE);

    const handleSkillModalUpdate = (value: DesiredSkill[] | DesiredSkill) => {
        if (Array.isArray(value) && value.length > 0) {
            const newSearchCriteria = { ...searchCriteria } as SearchRequest;
            value.forEach((item) => {
                const { id, desirability } = item;
                if (!desirability) return;
                if (desirability === 'MANDATORY') {
                    newSearchCriteria.skills = {
                        ...newSearchCriteria.skills,
                        [id]: { modifier: desirability, key: 'experience', value: 'ANY' },
                    };
                } else {
                    newSearchCriteria.skills = {
                        ...newSearchCriteria.skills,
                        [id]: { modifier: desirability, key: 'experience' },
                    };
                }
            });
            dispatch(setSearchCriteria(newSearchCriteria));
            // setSearchCriteria(!searchCriteria);
        } else {
            if (!searchCriteria.skills) {
                searchCriteria.skills = {};
            }
            const skillsIds = Object.keys(searchCriteria.skills);
            const { id, desirability } = value as DesiredSkill;
            if (skillsIds.length > 0) {
                const skillExist = skillsIds.find((item) => item === id);
                if (skillExist) {
                    dispatch(showErrorSnackbar('This skill has already been added. You can amend the weighting from within the table'));
                    return;
                }
            }
            const newSearchCriteria = { ...searchCriteria } as SearchRequest;
            if (!desirability) return;
            if (desirability === 'MANDATORY') {
                newSearchCriteria.skills = {
                    ...searchCriteria.skills,
                    [id]: { modifier: desirability, key: 'experience', value: 'ANY' },
                };
            } else {
                newSearchCriteria.skills = {
                    ...searchCriteria.skills,
                    [id]: { modifier: desirability, key: 'experience' },
                };
            }
            dispatch(setSearchCriteria(newSearchCriteria));
        }
        handleSkillModalClose();
    };

    useEffect(() => {
        dispatch(getOrganisations());
    }, [dispatch]);

    const JobQuery = useQuery(
        ['testjob', [...Object.keys(searchCriteria?.skills ?? {})]], //job
        async () => {
            // const response = await getInstance().get<{ data: any; error: string }>(`/public/job`);
            const response = await getInstance().post<any, any>(`/public/job/search`, {
                skillIds: Object.keys(searchCriteria?.skills ?? {}),
            });

            if (response.error) {
                throw new Error(response.error);
            }
            return response.data;
        },
        {
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    useEffect(() => {
        if (JobQuery && !showSavedJobs) {
            setJobData(JobQuery?.data);
        }
    }, [JobQuery, showSavedJobs]);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            renderCell: (params: any) => (
                <Link
                    to={`/jobs/${params.row.uuid}`}
                    state={{
                        from: location.pathname,
                    }}
                >
                    <StyledLink component="span">{params.row.id}</StyledLink>
                </Link>
            ),
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
        },
        {
            field: 'organization',
            headerName: 'Organisations',
            renderCell: (params: any) => <Typography>{(organizations || [])?.find((item) => item.id === params.row.organization)?.name}</Typography>,
            flex: 1,
        },
        {
            field: 'city',
            headerName: 'City',
            flex: 1,
        },
        {
            field: 'country',
            headerName: 'Country',
            flex: 1,
        },
        {
            field: 'minSecClearance',
            headerName: 'Min. Sec. Clearance',
            flex: 1,
            renderCell: (params: any) => {
                const securityClearance = params.row.securityClearance || [];
                if (Array.isArray(securityClearance)) {
                    return securityClearance?.map((item: string) => <Typography key={item}>{item}</Typography>);
                } else {
                    return <Typography>{securityClearance}</Typography>;
                }
            },
        },
    ];

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }
        const {
            draggableId: id,
            source: { index: sourceIndex, droppableId: sourceID },
            destination: { index: destinationIndex, droppableId: destinationID },
        } = result;

        if (sourceIndex === destinationIndex && sourceID === destinationID) {
            return;
        }
        const newSearchCriteria = { ...searchCriteria } as SearchRequest;
        if ((destinationID as SearchMemberModifier) === 'MANDATORY') {
            newSearchCriteria.skills = {
                ...searchCriteria.skills,
                [id]: { modifier: destinationID as SearchMemberModifier, key: 'experience', value: 'ANY' },
            };
        } else {
            newSearchCriteria.skills = {
                ...searchCriteria.skills,
                [id]: { modifier: destinationID as SearchMemberModifier, key: 'experience' },
            };
        }
        dispatch(setSearchCriteria(newSearchCriteria));
    }

    const copyTextToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        dispatch(showInfoSnackbar('Skill has been Copied to Clipboard', true));
    };

    const handleSkillDelete = (item: DesiredSkill) => {
        setShowSavedJobs(false);
        const newSearchCriteria = { ...searchCriteria, skills: { ...searchCriteria.skills } };
        const { id, desirability } = item;
        if (!desirability) return;
        if (newSearchCriteria.skills && newSearchCriteria.skills[id]) {
            //created new object to avoid mutation
            const updatedSkills = { ...newSearchCriteria.skills };
            delete updatedSkills[id];
            newSearchCriteria.skills = updatedSkills;
        }
        dispatch(setSearchCriteria(newSearchCriteria));
    };
    const handleCloseModal = () => {
        setEditSavedSearch('');
        setOpenSearchModal(false);
        fetchSavedResultFromLocalStorage();
        setSearchName('');
    };

    const deleteSavedSearch = (index: number) => {
        const filteredData = savedSearches.filter(({ id }) => id !== index);
        localStorage.setItem('jobBoard', JSON.stringify(filteredData));
        handleCloseModal();
    };

    function selectedSkills() {
        return (
            <Grid mt={2} ml={1} width={'100%'}>
                <Typography variant="h4">Jobs Board</Typography>
                <Button
                    variant="contained"
                    onClick={() => {
                        setDesirability('HIGHLY_DESIRABLE');
                        setSkillModal(true);
                    }}
                    sx={{ mt: 2, width: '100%' }}
                >
                    Job Search
                </Button>
                <Grid ml={'1rem'} mt={'2rem'} mb={'2rem'}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        {skillFilter.MANDATORY && (
                            <Droppable droppableId="MANDATORY">
                                {(provided: DroppableProvided) => (
                                    <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                        <Tooltip title="Add a Mandatory skill or experience">
                                            <div
                                                onClick={() => {
                                                    setDesirability('MANDATORY');
                                                    setSkillModal(true);
                                                }}
                                                className="desirability-title-container"
                                            >
                                                <Typography>Essential</Typography>
                                                <GridExpandMoreIcon color="primary" />
                                            </div>
                                        </Tooltip>
                                        {skillFilter.MANDATORY.map((item, idx) => (
                                            <Draggable key={item.id} draggableId={item.id} index={idx} isDragDisabled>
                                                {(provided: DraggableProvided) => (
                                                    <Grid
                                                        className="skill-drag-item"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {/* <Grid xs={1}>
                                                            <Tooltip title="Drag and drop to reprioritise">
                                                                <i className="fa-solid fa-grip"></i>
                                                            </Tooltip>
                                                        </Grid> */}
                                                        <Grid xs={10}>
                                                            <Tooltip title={item.display}>
                                                                <p
                                                                    className="skill-drag-text"
                                                                    onClick={() => {
                                                                        copyTextToClipboard(item.display);
                                                                    }}
                                                                >
                                                                    {item.display}
                                                                </p>
                                                            </Tooltip>
                                                        </Grid>
                                                        <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        )}

                        {skillFilter.HIGHLY_DESIRABLE && (
                            <Droppable droppableId="HIGHLY_DESIRABLE">
                                {(provided: DroppableProvided) => (
                                    <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                        <Tooltip title="Add a Highly Desirable skill or experience">
                                            <div
                                                onClick={() => {
                                                    setDesirability('HIGHLY_DESIRABLE');
                                                    setSkillModal(true);
                                                }}
                                                className="desirability-title-container"
                                            >
                                                <Typography>Highly Desirable</Typography>
                                                <GridExpandMoreIcon color="primary" />
                                            </div>
                                        </Tooltip>
                                        {skillFilter.HIGHLY_DESIRABLE.map((item, idx) => (
                                            <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                {(provided: DraggableProvided) => (
                                                    <Grid
                                                        className="skill-drag-item"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {/* <Grid xs={1}>
                                                            <Tooltip title="Drag and drop to reprioritise">
                                                                <i className="fa-solid fa-grip"></i>
                                                            </Tooltip>
                                                        </Grid> */}
                                                        <Grid xs={10}>
                                                            <Tooltip title={item.display}>
                                                                <p
                                                                    className="skill-drag-text"
                                                                    onClick={() => {
                                                                        copyTextToClipboard(item.display);
                                                                    }}
                                                                >
                                                                    {item.display}
                                                                </p>
                                                            </Tooltip>
                                                        </Grid>
                                                        <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        )}
                        {skillFilter.DESIRABLE && (
                            <Droppable droppableId="DESIRABLE">
                                {(provided: DroppableProvided) => (
                                    <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                        <Tooltip title="Add a Desirable skill or experience">
                                            <div
                                                onClick={() => {
                                                    setDesirability('DESIRABLE');
                                                    setSkillModal(true);
                                                }}
                                                className="desirability-title-container"
                                            >
                                                <Typography>Desirable</Typography>
                                                <ExpandMoreIcon color="primary" />
                                            </div>
                                        </Tooltip>
                                        {skillFilter.DESIRABLE.map((item, idx) => (
                                            <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                {(provided: DraggableProvided) => (
                                                    <Grid
                                                        className="skill-drag-item"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {/* <Grid xs={1}>
                                                            <Tooltip title="Drag and drop to reprioritise">
                                                                <i className="fa-solid fa-grip"></i>
                                                            </Tooltip>
                                                        </Grid> */}
                                                        <Grid xs={10}>
                                                            <Tooltip title={item.display}>
                                                                <p
                                                                    className="skill-drag-text"
                                                                    onClick={() => {
                                                                        copyTextToClipboard(item.display);
                                                                    }}
                                                                >
                                                                    {item.display}
                                                                </p>
                                                            </Tooltip>
                                                        </Grid>

                                                        <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        )}
                        {skillFilter.NICE_TO_HAVE && (
                            <Droppable droppableId="NICE_TO_HAVE">
                                {(provided: DroppableProvided) => (
                                    <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                        <Tooltip title="Add a Nice to Have skill or experience">
                                            <div
                                                onClick={() => {
                                                    setDesirability('NICE_TO_HAVE');
                                                    setSkillModal(true);
                                                }}
                                                className="desirability-title-container"
                                            >
                                                <Typography>Nice To Have</Typography>
                                                <ExpandMoreIcon color="primary" />
                                            </div>
                                        </Tooltip>
                                        {skillFilter.NICE_TO_HAVE.map((item, idx) => (
                                            <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                {(provided: DraggableProvided) => (
                                                    <Grid
                                                        className="skill-drag-item"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {/* <Grid xs={1}>
                                                            <Tooltip title="Drag and drop to reprioritise">
                                                                <i className="fa-solid fa-grip"></i>
                                                            </Tooltip>
                                                        </Grid> */}
                                                        <Grid xs={10}>
                                                            <Tooltip title={item.display}>
                                                                <p className="skill-drag-text" onClick={() => copyTextToClipboard(item.display)}>
                                                                    {item.display}
                                                                </p>
                                                            </Tooltip>
                                                        </Grid>
                                                        <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        )}
                    </DragDropContext>
                </Grid>

                <Button sx={{ mt: 2, width: '100%' }} variant="contained" onClick={() => setOpenSearchModal(true)}>
                    Save Search
                </Button>

                {openSearchModal && (
                    <Dialog open={openSearchModal}>
                        <DialogTitle>Save Your Search</DialogTitle>

                        <DialogContent sx={{ width: '300px' }}>
                            <Stack gap={2}>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <TextField
                                        fullWidth
                                        value={searchName}
                                        placeholder="Give search your name"
                                        onChange={(event) => {
                                            setSearchName(event.target.value);
                                        }}
                                    />
                                    {/* <Checkbox /> */}
                                </Box>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => handleCloseModal()}>
                                Cancel
                            </Button>

                            <Button
                                variant="contained"
                                onClick={() => {
                                    if (editSavedSearch) {
                                        const finalResult = savedSearches.map((data: any) => {
                                            if (data.id === editSavedSearch) {
                                                return {
                                                    ...data,
                                                    searchName,
                                                    createdAt: new Date(),
                                                };
                                            }
                                            return data;
                                        });
                                        localStorage.setItem('jobBoard', JSON.stringify(finalResult));
                                    } else {
                                        const searchToSave = [...savedSearches];
                                        searchToSave.push({
                                            id: new Date().getTime(),
                                            searchName,
                                            createdAt: new Date(),
                                            data: jobData ?? [],
                                            skillFilter,
                                        });

                                        localStorage.setItem('jobBoard', JSON.stringify(searchToSave));
                                    }
                                    handleCloseModal();
                                }}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
                {savedSearches.length > 0 && (
                    <>
                        {/* <Typography sx={{ mb: 2 }}>My Saved Searches</Typography> */}
                        <DataGrid columns={column} rows={savedSearches} sx={{ mt: 4 }} style={{ minHeight: 160, maxHeight: 200 }} />
                    </>
                )}
            </Grid>
        );
    }

    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={4}>
            <Grid mt="2rem" container sx={{ flexGrow: 0, width: { xs: '100%', md: '50%' } }}>
                {selectedSkills()}
            </Grid>
            <Grid container display={'flex'} justifyContent={'space-around'}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={4}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ButtonGroup variant="outlined">
                                    <Button onClick={() => setViewAs(ViewAs.CARD)}>Card View</Button>
                                    <Button onClick={() => setViewAs(ViewAs.TABLE)}>Table View</Button>
                                </ButtonGroup>
                            </div>
                        </Grid>
                    </Grid>
                    {viewAs === ViewAs.TABLE && (
                        <Grid item xs={12} mt={'2rem'}>
                            <DataGrid
                                style={{
                                    minHeight: 600,
                                }}
                                pageSize={10}
                                autoHeight
                                rows={jobData || []}
                                columns={columns}
                                rowHeight={38}
                                disableSelectionOnClick
                            />
                        </Grid>
                    )}
                    {viewAs === ViewAs.CARD && <ViewAsColumnJob rows={jobData || []} />}
                </Grid>
            </Grid>

            {/* Added wed 19 */}
            {/* <SearchCriteriaModal
                criteriaModalOpen={criteriaModalOpen}
                setCriteriaModalOpen={setCriteriaModalOpen}
                setModifier={setModifier}
                modifier={modifier}
                searchCriteria={searchCriteria}
            /> */}

            {skillModal && <SkillModal selectedDesirability={desirability} handleClose={handleSkillModalClose} handleSuccess={handleSkillModalUpdate} />}
        </Box>
    );
};

export default JobBoard;
