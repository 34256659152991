import { AppDispatch, RootState } from '../store';
import { doSearch } from '../../services';
import { showErrorSnackbar } from './snackbarAction';
import { ProfileResult, SearchRequest } from '../../types/search';

declare type GetState = () => RootState;

export const setSearchCriteria = (criteria: SearchRequest) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        dispatch({
            type: 'SET_SEARCH_CRITERIA',
            payload: criteria,
        });
        await doCandidateSearch()(dispatch, getState);
    } catch (e: any) {
        dispatch({
            type: 'SET_SEARCH_CRITERIA_ERROR',
            payload: e?.message,
        });
    }
};

export const doCandidateSearch = () => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        const searchCriteria = getState().search.searchCriteria;
        const _searchResults = await doSearch(searchCriteria);

        if ((_searchResults as any)?.error === 'Unauthorized') {
            throw new Error((_searchResults as any)?.error);
        }
        dispatch({
            type: 'SET_SEARCH_RESULTS',
            payload: _searchResults,
        });
    } catch (e: any) {
        dispatch(showErrorSnackbar(`An error occurred while loading the search results`));
    }
};

export const clearSearch = () => (dispatch: AppDispatch) => {
    try {
        dispatch({
            type: 'SET_SEARCH_RESULTS',
            payload: {
                results: [] as ProfileResult[],
            },
        });
        dispatch({
            type: 'SET_SEARCH_CRITERIA',
            payload: {} as SearchRequest,
        });
    } catch (e: any) {
        dispatch(showErrorSnackbar(`An error occurred while loading the search results`));
    }
};
