import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    affirmativeActionFilter,
    auCitizenshipStatusDropdown,
    searchAttributeModifier,
    securityClearanceRadio,
    seekingDropdown,
} from '../../../../data/dropDownData';
import { Skill } from '../../../../types/skill';
import { selectSkillsData } from '../../../../redux/selectors/skillsSelector';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useEffect, useState } from 'react';
import SkillsAccordion from '../../../../components/filter/skills/Accordin';
import SkillModal from '../../../../components/filter/skills/SkillModal';
import { SearchMemberModifier, SearchRequest, SearchRequestProfile } from '../../../../types/search';
import { showErrorSnackbar, showInfoSnackbar } from '../../../../redux/actions/snackbarAction';
import SearchResultsTable from '../../../../components/SearchResultsTable';
import { clearSearch, setSearchCriteria } from '../../../../redux/actions/searchAction';
import { selectSearchCriteria, selectSearchResults } from '../../../../redux/selectors/searchSelector';
import { selectGeographyData } from '../../../../redux/selectors/geographySelector';
import { GridSelectionModel } from '@mui/x-data-grid';
import { DragDropContext, Draggable, DraggableProvided, DropResult, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GenericModal } from '../../../../components/modal';

type skillFilter = {
    MANDATORY: DesiredSkill[];
    HIGHLY_DESIRABLE: DesiredSkill[];
    DESIRABLE: DesiredSkill[];
    NICE_TO_HAVE: DesiredSkill[];
};
type DesiredSkill = Skill & { desirability?: SearchMemberModifier };
const defaultSkillFilter: skillFilter = {
    MANDATORY: [],
    HIGHLY_DESIRABLE: [],
    DESIRABLE: [],
    NICE_TO_HAVE: [],
};
const Skills = ({
    values,
    setValue,
    save,
    saveDescription,
    setDescription,
}: {
    values?: any;
    setValue?: (data: any) => void;
    save?: any;
    saveDescription: any;
    setDescription: (data: any) => void;
}) => {
    const [skillModal, setSkillModal] = useState<boolean>(false);
    const [securityClearanceModal, setSecurityClearanceModal] = useState<boolean>(false);
    const [skillFilter, setSkillFilter] = useState<skillFilter>(defaultSkillFilter);
    const [desirability, setDesirability] = useState<SearchMemberModifier>('NICE_TO_HAVE');
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [filterOpen, setFilterOpen] = useState({
        filter: true,
        securityClearance: false,
        citizenShip: false,
        employmentModal: false,
        affirmativeAction: false,
    });
    const [filterData, setFilterData] = useState<SearchRequestProfile>({
        auCitizenshipStatus: [],
        securityClearance: [],
        aboriginalOrTorresStrait: false,
        disabled: false,
        genderFemale: false,
        genderNonBinary: false,
        veteranStatus: false,
        defencePartner: false,
        workforceReturner: false,
        seeking: [],
    } as SearchRequestProfile);
    const skills = useSelector(selectSkillsData);
    const searchCriteria = useSelector(selectSearchCriteria);
    const searchResults = useSelector(selectSearchResults);
    const geography = useSelector(selectGeographyData);
    const dispatch = useDispatch();
    const { jobId } = useParams();

    useEffect(() => {
        const searchCriteria = values.skills as SearchRequest;
        const { profile, skills: searchSkills } = searchCriteria;
        if (profile) {
            let { auCitizenshipStatus, securityClearance } = profile;
            const {
                genderFemale = false,
                genderNonBinary = false,
                workforceReturner = false,
                veteranStatus = false,
                aboriginalOrTorresStrait = false,
                disabled = false,
                defencePartner = false,
                seeking,
            } = profile;
            if (!auCitizenshipStatus) {
                auCitizenshipStatus = [];
            }
            if (!securityClearance) {
                securityClearance = [];
            }
            setFilterData({
                auCitizenshipStatus,
                securityClearance,
                genderFemale,
                genderNonBinary,
                workforceReturner,
                veteranStatus,
                aboriginalOrTorresStrait,
                disabled,
                defencePartner,
                seeking,
            });
        }
        if (searchSkills) {
            const allSkills = Object.entries(searchSkills);
            const skillFilter: skillFilter = {
                MANDATORY: [],
                HIGHLY_DESIRABLE: [],
                DESIRABLE: [],
                NICE_TO_HAVE: [],
            };
            searchAttributeModifier.forEach((item) => {
                const result = [] as DesiredSkill[];
                allSkills.forEach(([id, values]) => {
                    if (item.value === values.modifier) {
                        const selectedSkill = skills.find((skill) => skill.id === id);
                        if (!selectedSkill) {
                            // handle if skill doesn't exist
                            return;
                        }
                        result.push({ ...selectedSkill, desirability: searchSkills[id].modifier } as DesiredSkill);
                    }
                });
                skillFilter[item.value as keyof skillFilter] = result;
            });
            setSkillFilter(skillFilter);
        }
    }, [skills, values]);

    const handleSkillDelete = (item: DesiredSkill) => {
        const searchCriteria = values.skills;
        // Create a copy of the skills object to avoid mutating the original state
        const newSkills = { ...searchCriteria.skills };
        const newSearchCriteria = { skills: newSkills, ...searchCriteria } as SearchRequest;
        const { id, desirability } = item;
        if (!desirability || !newSkills[id]) return;
        // Create a new object without the specified skill
        const updatedSkills = Object.keys(newSkills).reduce((acc: any, skillId: any) => {
            if (skillId !== id) {
                acc[skillId] = newSkills[skillId];
            }
            return acc;
        }, {});
        newSearchCriteria.skills = updatedSkills;
        setValue &&
            setValue({
                target: {
                    name: 'skills',
                    value: newSearchCriteria,
                },
            });
    };

    const handleSkillModalClose = () => {
        setSkillModal(false);
    };

    const handleSkillModalUpdate = (value: DesiredSkill[] | DesiredSkill) => {
        if (Array.isArray(value) && value.length > 0) {
            const newSearchCriteria = { ...searchCriteria } as SearchRequest;
            value.forEach((item) => {
                const { id, desirability } = item;
                if (!desirability) return;
                if (desirability === 'MANDATORY') {
                    newSearchCriteria.skills = {
                        ...newSearchCriteria.skills,
                        [id]: { modifier: desirability, key: 'experience', value: 'ANY' },
                    };
                } else {
                    newSearchCriteria.skills = {
                        ...newSearchCriteria.skills,
                        [id]: { modifier: desirability, key: 'experience' },
                    };
                }
            });
            setValue &&
                setValue({
                    target: {
                        name: 'skills',
                        value: newSearchCriteria,
                    },
                });
            setSkillModal(!skillModal);
        } else {
            if (!searchCriteria.skills) {
                searchCriteria.skills = {};
            }
            const skillsIds = Object.keys(searchCriteria.skills);
            const { id, desirability } = value as DesiredSkill;
            if (skillsIds.length > 0) {
                const skillExist = skillsIds.find((item) => item === id);
                if (skillExist) {
                    dispatch(showErrorSnackbar('This skill has already been added. You can amend the weighting from within the table'));
                    return;
                }
            }
            const newSearchCriteria = { ...searchCriteria } as SearchRequest;
            if (!desirability) return;
            if (desirability === 'MANDATORY') {
                newSearchCriteria.skills = {
                    ...searchCriteria.skills,
                    [id]: { modifier: desirability, key: 'experience', value: 'ANY' },
                };
            } else {
                newSearchCriteria.skills = {
                    ...searchCriteria.skills,
                    [id]: { modifier: desirability, key: 'experience' },
                };
            }
            setValue &&
                setValue({
                    target: {
                        name: 'skills',
                        value: newSearchCriteria,
                    },
                });
            setSkillModal(!skillModal);
        }
    };

    const handleSkillSave = () => {
        const selectedSecurityClearance = values.description.securityClearance;
        const value = filterData.securityClearance?.join(',') || '';
        const selectedSecurityClearanceValue =
            securityClearanceRadio.find((item) => {
                if (!item.value || !selectedSecurityClearance[0]) {
                    return;
                }
                return (
                    item.label
                        .replace(/\(and above\)/, '')
                        .toLowerCase()
                        .trim() === selectedSecurityClearance[0].toLowerCase().trim()
                );
            })?.value || '';
        if (selectedSecurityClearanceValue.toString() !== value.toString()) {
            setSecurityClearanceModal(true);
            return;
        }
        save && save();
    };

    const handleSecurityClearanceChange = (checked: boolean, value: string) => {
        const searchCriteria = values.skills as SearchRequest;
        const newSearchCriteria = { ...searchCriteria } as SearchRequest;
        if (!newSearchCriteria.profile) {
            newSearchCriteria.profile = {};
        }
        if (!checked) {
            newSearchCriteria.profile = { ...newSearchCriteria.profile, ['securityClearance']: value.split(',').filter((item) => item !== '') };
            setFilterData({ ...filterData, securityClearance: value.split(',') });
        } else {
            setFilterData({ ...filterData, securityClearance: [] });
            newSearchCriteria.profile = { ...newSearchCriteria.profile, ['securityClearance']: [] };
        }
        setValue && setValue({ target: { name: 'skills', value: newSearchCriteria } });
    };

    const handleSecurityClearanceModalClose = () => {
        setSecurityClearanceBasedOnDescription(values.description.securityClearance[0]);
        setSecurityClearanceModal(false);
        // save && save();
    };

    const handleSecurityClearanceModalSuccess = () => {
        setSecurityClearanceModal(false);
        const securityClearanceValue = filterData.securityClearance?.join(',');
        const value = [
            securityClearanceRadio.find((item) => {
                const h = item.value === securityClearanceValue;
                return h;
            })?.alt,
        ];
        setDescription && setDescription({ target: { name: 'securityClearance', value } });
        saveDescription && saveDescription();
    };

    const handleCitizenshipStatusCheckbox = (event: any, item: string) => {
        const {
            target: { checked },
        } = event;

        // Create a deep copy of the existing search criteria to avoid direct mutation
        const newSearchCriteria: SearchRequest = {
            ...values.skills,
            profile: {
                ...values.skills.profile,
                auCitizenshipStatus: values.skills.profile?.auCitizenshipStatus ? [...values.skills.profile.auCitizenshipStatus] : [],
            },
        };

        // Ensure profile is defined
        if (!newSearchCriteria.profile) {
            newSearchCriteria.profile = { auCitizenshipStatus: [] };
        }

        // Update the auCitizenshipStatus array based on whether the checkbox is checked or not

        if (checked) {
            newSearchCriteria?.profile?.auCitizenshipStatus?.push(item);
        } else {
            newSearchCriteria.profile.auCitizenshipStatus = newSearchCriteria.profile.auCitizenshipStatus?.filter((status) => status !== item);
        }

        // Call setValue to update the state

        setValue && setValue({ target: { name: 'skills', value: newSearchCriteria } });
    };
    const handleEmploymentModalCheckbox = (event: any, item: string) => {
        const {
            target: { checked },
        } = event;
        const newSearchCriteria = values.skills as SearchRequest;
        if (checked) {
            if (!newSearchCriteria.profile) {
                newSearchCriteria.profile = {};
            }
            if (!newSearchCriteria.profile.seeking) {
                newSearchCriteria.profile.seeking = [];
            }
            newSearchCriteria.profile = { ...newSearchCriteria.profile, ['seeking']: [...newSearchCriteria.profile.seeking, item] };
        } else {
            if (!newSearchCriteria.profile) {
                newSearchCriteria.profile = {};
            }
            if (!newSearchCriteria.profile.seeking) {
                newSearchCriteria.profile.seeking = [];
            }

            newSearchCriteria.profile = {
                ...newSearchCriteria.profile,
                ['seeking']: [...newSearchCriteria.profile.seeking.filter((status) => status !== item)],
            };
        }

        setValue && setValue({ target: { name: 'skills', value: newSearchCriteria } });
    };

    const handleAffirmativeActionCheckbox = (event: any, data: any) => {
        const {
            target: { checked },
        } = event;
        const value = data.value as keyof SearchRequestProfile;

        const newSearchCriteria = { ...values.skills } as SearchRequest;
        if (!newSearchCriteria.profile) {
            return;
        }
        newSearchCriteria.profile = { ...newSearchCriteria.profile, [value]: checked };
        setValue && setValue({ target: { name: 'skills', value: { ...searchCriteria, ...newSearchCriteria } } });
    };

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        const {
            draggableId: id,
            source: { index: sourceIndex, droppableId: sourceID },
            destination: { index: destinationIndex, droppableId: destinationID },
        } = result;

        if (sourceIndex === destinationIndex && sourceID === destinationID) {
            return;
        }
        const newSearchCriteria = { ...searchCriteria } as SearchRequest;
        if ((destinationID as SearchMemberModifier) === 'MANDATORY') {
            newSearchCriteria.skills = {
                ...searchCriteria.skills,
                [id]: { modifier: destinationID as SearchMemberModifier, key: 'experience', value: 'ANY' },
            };
        } else {
            newSearchCriteria.skills = {
                ...searchCriteria.skills,
                [id]: { modifier: destinationID as SearchMemberModifier, key: 'experience' },
            };
        }

        setValue &&
            setValue({
                target: {
                    name: 'skills',
                    value: newSearchCriteria,
                },
            });
    }
    useEffect(() => {
        return () => {
            setSkillFilter(defaultSkillFilter);
            dispatch(clearSearch());
        };
    }, [dispatch]);

    const setSecurityClearanceBasedOnDescription = (value: string) => {
        if (value) {
            if (value === 'NONE') {
                // eslint-disable-next-line no-debugger
                // return;
                setFilterData((old) => ({ ...old, securityClearance: [] }));
            }

            const securityClearanceValue =
                securityClearanceRadio.find((item) => {
                    if (!item.value || !value) {
                        return;
                    }
                    return (
                        item.label
                            .replace(/\(and above\)/, '')
                            .toLowerCase()
                            .trim() === value.toLowerCase().trim()
                    );
                })?.value || '';

            handleSecurityClearanceChange(false, securityClearanceValue);
        }
    };

    useEffect(() => {
        const selectedSecurityClearance = values.description.securityClearance;
        setSecurityClearanceBasedOnDescription(selectedSecurityClearance[0]);
        // need to run this hook only once but and we need securityClearance as dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const copyTextToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        dispatch(showInfoSnackbar('Skill has been Copied to Clipboard', true));
    };

    const checkFilterSecurityClearance = (securityClearance: string[] | undefined) => {
        if (!securityClearance || securityClearance.length === 0 || securityClearance[0] === '') {
            return false;
        }
        return true;
    };
    if (!jobId) {
        return <> </>;
    }

    const renderCurrentSecurityClearance = () => {
        const value = filterData.securityClearance?.join(',') || '';
        const selectedSecurityClearanceValue =
            securityClearanceRadio.find((item) => {
                return item.value === value;
            })?.label || '';
        return selectedSecurityClearanceValue.replace(/\(and above\)/, '');
    };

    return (
        <Box>
            <Grid container mt={'3rem'} display={'flex'} justifyContent={'space-around'}>
                <Grid item xs={3}>
                    <Tooltip title="Add skills and experience to describe your ideal candidate">
                        <Typography variant="h5" color={'primary'} ml={'2rem'}>
                            Describe your ideal Candidate
                        </Typography>
                    </Tooltip>
                    <Grid item ml={'1rem'} mt={'1rem'}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                setDesirability('HIGHLY_DESIRABLE');
                                setSkillModal(true);
                            }}
                        >
                            Add Skill or Experience
                        </Button>
                    </Grid>
                    <Grid ml={'1rem'} mt={'2rem'} mb={'2rem'}>
                        <Typography variant="h6" color={'primary'} mb={'1rem'}>
                            Skills and Experience
                        </Typography>
                        <DragDropContext onDragEnd={onDragEnd}>
                            {skillFilter.MANDATORY && (
                                <Droppable droppableId="MANDATORY">
                                    {(provided: DroppableProvided) => (
                                        <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                            <Tooltip title="Add a Mandatory skill or experience">
                                                <div
                                                    onClick={() => {
                                                        setDesirability('MANDATORY');
                                                        setSkillModal(true);
                                                    }}
                                                    className="desirability-title-container"
                                                >
                                                    <Typography>Essential</Typography>
                                                    <ExpandMoreIcon color="primary" />
                                                </div>
                                            </Tooltip>
                                            {skillFilter.MANDATORY.map((item, idx) => (
                                                <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                    {(provided: DraggableProvided) => (
                                                        <Grid
                                                            className="skill-drag-item"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Grid xs={1}>
                                                                <Tooltip title="Drag and drop to reprioritise">
                                                                    <i className="fa-solid fa-grip"></i>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid xs={10}>
                                                                <Tooltip title={item.display}>
                                                                    <p
                                                                        className="skill-drag-text"
                                                                        onClick={() => {
                                                                            copyTextToClipboard(item.display);
                                                                        }}
                                                                    >
                                                                        {item.display}
                                                                    </p>
                                                                </Tooltip>
                                                            </Grid>
                                                            <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                        </Grid>
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}

                            {skillFilter.HIGHLY_DESIRABLE && (
                                <Droppable droppableId="HIGHLY_DESIRABLE">
                                    {(provided: DroppableProvided) => (
                                        <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                            <Tooltip title="Add a Highly Desirable skill or experience">
                                                <div
                                                    onClick={() => {
                                                        setDesirability('HIGHLY_DESIRABLE');
                                                        setSkillModal(true);
                                                    }}
                                                    className="desirability-title-container"
                                                >
                                                    <Typography>Highly Desirable</Typography>
                                                    <ExpandMoreIcon color="primary" />
                                                </div>
                                            </Tooltip>
                                            {skillFilter.HIGHLY_DESIRABLE.map((item, idx) => (
                                                <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                    {(provided: DraggableProvided) => (
                                                        <Grid
                                                            className="skill-drag-item"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Grid xs={1}>
                                                                <Tooltip title="Drag and drop to reprioritise">
                                                                    <i className="fa-solid fa-grip"></i>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid xs={10}>
                                                                <Tooltip title={item.display}>
                                                                    <p
                                                                        className="skill-drag-text"
                                                                        onClick={() => {
                                                                            copyTextToClipboard(item.display);
                                                                        }}
                                                                    >
                                                                        {item.display}
                                                                    </p>
                                                                </Tooltip>
                                                            </Grid>
                                                            <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                        </Grid>
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}
                            {skillFilter.DESIRABLE && (
                                <Droppable droppableId="DESIRABLE">
                                    {(provided: DroppableProvided) => (
                                        <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                            <Tooltip title="Add a Desirable skill or experience">
                                                <div
                                                    onClick={() => {
                                                        setDesirability('DESIRABLE');
                                                        setSkillModal(true);
                                                    }}
                                                    className="desirability-title-container"
                                                >
                                                    <Typography>Desirable</Typography>
                                                    <ExpandMoreIcon color="primary" />
                                                </div>
                                            </Tooltip>
                                            {skillFilter.DESIRABLE.map((item, idx) => (
                                                <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                    {(provided: DraggableProvided) => (
                                                        <Grid
                                                            className="skill-drag-item"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Grid xs={1}>
                                                                <Tooltip title="Drag and drop to reprioritise">
                                                                    <i className="fa-solid fa-grip"></i>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid xs={10}>
                                                                <Tooltip title={item.display}>
                                                                    <p
                                                                        className="skill-drag-text"
                                                                        onClick={() => {
                                                                            copyTextToClipboard(item.display);
                                                                        }}
                                                                    >
                                                                        {item.display}
                                                                    </p>
                                                                </Tooltip>
                                                            </Grid>

                                                            <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                        </Grid>
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}
                            {skillFilter.NICE_TO_HAVE && (
                                <Droppable droppableId="NICE_TO_HAVE">
                                    {(provided: DroppableProvided) => (
                                        <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                            <Tooltip title="Add a Nice to Have skill or experience">
                                                <div
                                                    onClick={() => {
                                                        setDesirability('NICE_TO_HAVE');
                                                        setSkillModal(true);
                                                    }}
                                                    className="desirability-title-container"
                                                >
                                                    <Typography>Nice To Have</Typography>
                                                    <ExpandMoreIcon color="primary" />
                                                </div>
                                            </Tooltip>
                                            {skillFilter.NICE_TO_HAVE.map((item, idx) => (
                                                <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                    {(provided: DraggableProvided) => (
                                                        <Grid
                                                            className="skill-drag-item"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Grid xs={1}>
                                                                <Tooltip title="Drag and drop to reprioritise">
                                                                    <i className="fa-solid fa-grip"></i>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid xs={10}>
                                                                <Tooltip title={item.display}>
                                                                    <p className="skill-drag-text" onClick={() => copyTextToClipboard(item.display)}>
                                                                        {item.display}
                                                                    </p>
                                                                </Tooltip>
                                                            </Grid>
                                                            <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                        </Grid>
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}
                        </DragDropContext>
                    </Grid>
                    <Typography variant="h6" color={'primary'} marginLeft={'1rem'} marginBottom={'1rem'}>
                        Other Filters
                    </Typography>
                    <Grid marginLeft={'1rem'}>
                        <Grid item>
                            <SkillsAccordion
                                title="Minimum Aust. Security Clearance"
                                open={filterOpen.securityClearance}
                                onChange={(value) => {
                                    setFilterOpen({ ...filterOpen, securityClearance: value });
                                }}
                            >
                                <>
                                    <Typography variant="caption" color={'GrayText'} fontStyle={'italic'}>
                                        (Includes pending and lapsed clearances)
                                    </Typography>
                                    <RadioGroup value={filterData.securityClearance?.join(',')} name="radio-buttons-group" sx={{ marginTop: 0 }}>
                                        {securityClearanceRadio.map((item) => (
                                            <FormControlLabel
                                                key={item.key}
                                                value={item.value}
                                                control={
                                                    <Radio
                                                        onClick={() => {
                                                            const checked = item.value === filterData.securityClearance?.join(',') || false;
                                                            handleSecurityClearanceChange(checked, item.value);
                                                        }}
                                                        checked={item.value === filterData.securityClearance?.join(',') || false}
                                                        size="small"
                                                    />
                                                }
                                                label={<Typography variant="body2">{item.label}</Typography>}
                                            />
                                        ))}
                                    </RadioGroup>
                                </>
                            </SkillsAccordion>

                            {checkFilterSecurityClearance(filterData.securityClearance) && (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <i className="fa-regular fa-square-check mr-1 dropdown-icons"></i>
                                        <Typography variant="body2">
                                            {securityClearanceRadio.find((item) => item.value === filterData.securityClearance?.join(','))?.label}
                                        </Typography>
                                    </div>
                                </>
                            )}
                        </Grid>
                        <Grid item>
                            <SkillsAccordion
                                title="Employment Models"
                                open={filterOpen.employmentModal}
                                onChange={(value) => {
                                    setFilterOpen({ ...filterOpen, employmentModal: value });
                                }}
                            >
                                <FormGroup>
                                    {seekingDropdown.map((data) => (
                                        <FormControlLabel
                                            key={data}
                                            control={
                                                <Checkbox
                                                    onClick={(event) => {
                                                        handleEmploymentModalCheckbox(event, data);
                                                    }}
                                                    checked={filterData.seeking?.includes(data) || false}
                                                    name={data}
                                                />
                                            }
                                            label={<Typography variant="body2">{data}</Typography>}
                                        />
                                    ))}
                                </FormGroup>
                            </SkillsAccordion>
                            {!filterOpen.employmentModal && (
                                <FormGroup>
                                    {(filterData.seeking || []).map((item) => (
                                        <div key={item} style={{ display: 'flex', alignItems: 'center' }}>
                                            <i className="fa-regular fa-square-check mr-1 dropdown-icons"></i>
                                            <Typography variant="body2">{item.replace(/_/g, ' ')}</Typography>
                                        </div>
                                    ))}
                                </FormGroup>
                            )}
                        </Grid>
                        <Grid item>
                            <SkillsAccordion
                                title="Affirmative Action"
                                open={filterOpen.affirmativeAction}
                                onChange={(value) => {
                                    setFilterOpen({ ...filterOpen, affirmativeAction: value });
                                }}
                            >
                                <FormGroup>
                                    {affirmativeActionFilter.map((data) => (
                                        <FormControlLabel
                                            key={data.key}
                                            control={
                                                <Checkbox
                                                    onClick={(event) => {
                                                        handleAffirmativeActionCheckbox(event, data);
                                                    }}
                                                    checked={
                                                        filterData[
                                                            data.value as keyof Omit<
                                                                SearchRequestProfile,
                                                                'auCitizenshipStatus' | 'securityClearance' | 'seeking' | 'candidateId'
                                                            >
                                                        ] || false
                                                    }
                                                    name={data.value}
                                                />
                                            }
                                            label={data.label}
                                        />
                                    ))}
                                </FormGroup>
                            </SkillsAccordion>
                        </Grid>
                    </Grid>

                    <Grid item display={'flex'} justifyContent={'center'} mt={'2rem'}>
                        <Button variant="contained" onClick={handleSkillSave}>
                            Save Search Criteria
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <Grid item>
                        {/* we are sending the both uuid and id right now to get old data */}
                        <SearchResultsTable
                            columns={searchCriteria}
                            rows={searchResults}
                            skills={skills}
                            geography={geography}
                            selectionModel={selectionModel}
                            setSelectionModel={setSelectionModel}
                            setSearchCriteria={setSearchCriteria}
                            searchCriteriaObject={{ ...searchCriteria, ...values.skills, jobId: `${values.settings.id},${jobId}` }}
                            showJobAssessment={true}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {skillModal && <SkillModal selectedDesirability={desirability} handleClose={handleSkillModalClose} handleSuccess={handleSkillModalUpdate} />}

            {securityClearanceModal && (
                <GenericModal
                    handleClose={handleSecurityClearanceModalClose}
                    handleSuccess={handleSecurityClearanceModalSuccess}
                    successLabel={`Save as New - ${renderCurrentSecurityClearance()}`}
                    cancelLabel={`Save as Current - ${values?.description?.securityClearance}`}
                >
                    <>
                        <Typography gutterBottom textAlign={'center'} variant="h6">
                            SETTINGS MISMATCH
                        </Typography>
                        <Typography gutterBottom>
                            The Job Description lists a Minimum Security Clearance of <b>{values?.description?.securityClearance}</b>.
                        </Typography>
                    </>
                </GenericModal>
            )}
        </Box>
    );
};
export default Skills;
