import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Typography, Link, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import { ProfileFiles, ProfileOverview } from '../../../types/CandidateProfile';
import PingModal from '../../../components/PingModal';
import SubscribeBanner from '../../../components/SubscribeBanner';
import { Skill } from '../../../types/skill';
import { downloadProfileFile } from '../../../redux/actions/filesAction';
import { getSkillsAnswers, getSkillsFramework } from '../../../redux/actions/skillsAction';
import { getGeographyFramework } from '../../../redux/actions/geographyAction';
import { selectIsUserEmployer } from '../../../redux/selectors/userSelector';
import { selectSkillsData } from '../../../redux/selectors/skillsSelector';
import { getOrganisations } from '../../../redux/actions/organisationsAction';
import { selectProfileData } from '../../../redux/selectors/profileSelector';
import { securityClearanceDropdown, profileReadiness } from '../../../data/dropDownData';
import { mapValueToLabel } from '../../../utils/mapValueToLabel';
import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../../../services/fetch';
import { formatUrl } from '../../../utils/url';

declare interface MappedSkill {
    id: number;
    skillset: string;
    reference: string;
    qualification: string;
    experience: string;
    summary: string;
}

declare interface AddressLine {
    city: string;
    state: string;
    country: string;
    postcode: string;
}

function Profile() {
    const dispatch = useDispatch();
    const { candidateId: userId } = useParams();

    const isUserEmployer = useSelector(selectIsUserEmployer);
    const profileData = useSelector(selectProfileData);
    const skills = useSelector(selectSkillsData);
    const [pingModalOpen, setPingModalOpen] = useState(false);
    const [pingModalUserId, setPingModalUserId] = useState('');

    const profileOverviewQuery = useQuery<ProfileOverview>(
        ['profile-overview', userId],
        async () => {
            const response = await getInstance().get<{ data: any; error: string }>(`/employer/search/profile?id=${userId}`);

            return {
                ...response.data,
                userId,
            };
        },
        {
            enabled: !!userId,
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    const profileOverview = profileOverviewQuery.data as ProfileOverview;

    useEffect(() => {
        dispatch(getSkillsFramework());
        dispatch(getSkillsAnswers());
        dispatch(getGeographyFramework());
        dispatch(getOrganisations());
    }, [dispatch]);

    if (!profileOverview) {
        return (
            <Grid sx={{ flexGrow: 1, pt: 8, pb: 2 }} container spacing={2} direction="row" display="flex" justifyContent="center">
                {profileOverviewQuery.isLoading ? (
                    <CircularProgress />
                ) : (
                    <div>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                            Error loading profile
                        </Typography>
                    </div>
                )}
            </Grid>
        );
    }

    const showPingModal = (userId: string) => {
        setPingModalOpen(true);
        setPingModalUserId(userId);
    };

    function handleDownload(uuid: string) {
        dispatch(downloadProfileFile(uuid, profileOverview.userId));
    }

    function getSkillFromRef(ref: string) {
        const skill = skills.find((skill: Skill) => skill.id === ref) || {};
        return skill;
    }

    const mappedSkills = Object.keys(profileOverview.skills || []).flatMap((key, idx) => {
        const answer = profileOverview.skills[key];
        const currSkill = getSkillFromRef(key) as Skill;
        const qualVal = (currSkill?.qualFramework || {})[answer.qualification || '']?.display || '';
        const expVal = (currSkill?.expFramework || {})[answer.experience || '']?.display || '';

        if (!currSkill.enabled) {
            return [];
        }

        return [
            {
                id: idx + 1,
                skillset: currSkill.display,
                reference: key,
                qualification: qualVal,
                experience: expVal,
                summary: answer.summary,
            } as MappedSkill,
        ];
    });

    const combineAddress = (address: Partial<AddressLine>) => {
        const { city, state, country, postcode } = address;
        return [city, state, country, postcode].filter(Boolean).join(', ');
    };

    return (
        <>
            {!isUserEmployer && <SubscribeBanner />}

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {/* Availability details */}
                    <Box padding={1}>
                        <Grid container display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'0.5rem'}>
                            <Typography color="primary" variant="h6">
                                {' '}
                                <b>Overview</b>
                            </Typography>
                        </Grid>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid sx={{ flexGrow: 1 }} container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Candidate ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ display: 'inline', fontWeight: '500' }}>{profileOverview.profile.candidateId}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Jobseeking Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {/* <Chip size="small" label={profileOverview.profile.readiness} color="success" /> */}
                                        <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {mapValueToLabel(profileReadiness, profileOverview.profile.readiness || '')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Employment Models:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {(profileOverview.profile.seeking || []).join(', ')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Mobility:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {profileOverview.profile.mobilityComment || ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Availability:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {profileOverview.profile.availabilityComment || ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Security Clearance:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {Array.isArray(profileOverview.profile.securityClearance)
                                                ? (profileOverview.profile.securityClearance || [])
                                                      .map((option) => {
                                                          return mapValueToLabel(securityClearanceDropdown, option);
                                                      })
                                                      .join(', ')
                                                : mapValueToLabel(securityClearanceDropdown, profileOverview.profile.securityClearance || '')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Location:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {combineAddress({
                                                city: profileOverview.profile.location?.city,
                                                state: profileOverview.profile.location?.state,
                                                country: profileOverview.profile.location?.country,
                                                postcode: profileOverview.profile.location?.postcode,
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Nationalities:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500' }} style={{ textTransform: 'capitalize' }}>
                                            {(profileOverview.profile.nationality || []).join(', ')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Australian Citizenship Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ display: 'inline', mr: 1, mt: 20, fontWeight: '500' }} style={{ textTransform: 'capitalize' }}>
                                            {profileOverview.profile.auCitizenshipStatus}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Last Updated:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ display: 'inline', mr: 1, mt: 20, fontWeight: '500' }} style={{ textTransform: 'capitalize' }}>
                                            {profileOverview.profile.lastUpdated}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* Candidate details */}
                    <Box padding={1}>
                        <Grid container display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'0.5rem'}>
                            <Typography color="primary" variant="h6">
                                {' '}
                                <b>Identity</b>
                            </Typography>
                        </Grid>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid
                                    sx={{ flexGrow: 1 }}
                                    container
                                    spacing={1}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    wrap="wrap"
                                >
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline' }}>Name: </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ display: 'inline', fontWeight: '500' }}>
                                            {profileOverview.profile.firstname} {profileOverview.profile.surname}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Email:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Link href={`mailto:${profileOverview.profile.email}`}>
                                            <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>{profileOverview.profile.email}</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Phone Number:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>{profileOverview.profile.phoneNumber}</Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>LinkedIn URL:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {profileOverview.profile.linkedInUrl && (
                                            <Link href={formatUrl(profileOverview.profile.linkedInUrl)} target="_blank" rel="noreferrer">
                                                <Typography sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                                    {profileOverview.profile.linkedInUrl}
                                                </Typography>
                                            </Link>
                                        )}
                                    </Grid>
                                    {/* Attachments */}
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Attachments:</Typography>
                                    </Grid>
                                    <Grid item xs={8} />
                                    <Grid item xs={12}>
                                        <Typography>
                                            {profileOverview.files?.files?.map((record: ProfileFiles) => (
                                                <Button
                                                    key={record.uuid}
                                                    variant="outlined"
                                                    onClick={() => handleDownload(record.uuid)}
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    {record.name}
                                                </Button>
                                            ))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {profileOverview.profile?.readiness === 'OPEN' && (
                            <Alert icon={false} severity="success">
                                Contact details and attachments have been withheld by the candidate. Contact the candidate to introduce yourself and request
                                release of their details.
                            </Alert>
                        )}
                        <Grid item xs={12} mt={'2rem'} sx={{ textAlign: 'left', marginLeft: '1rem' }}>
                            <Button variant="contained" onClick={() => showPingModal(profileOverview.userId)} disabled={!isUserEmployer}>
                                Contact candidate
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            {/* Career Summary & Aspirations */}
            <Box padding={1}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: '500' }}>Career Summary & Aspirations</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{profileOverview.profile.careerSummary}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>

            <Box padding={1}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid sx={{ flexGrow: 1 }} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={12} sx={{ padding: '0 5px 15px 5px' }}>
                                <div style={{ display: 'inline-block', minWidth: '40%', fontWeight: '500', textAlign: 'left' }}>Skills or Experience</div>
                                <div style={{ display: 'inline-block', minWidth: '20%', fontWeight: '500', textAlign: 'left' }}>Experience</div>
                                <div style={{ display: 'inline-block', minWidth: '40%', fontWeight: '500', textAlign: 'left' }}>
                                    Competency / Qualification Level
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {mappedSkills.map((item) => {
                                    return item.summary ? (
                                        // with summary
                                        <details key={item.id}>
                                            <summary>
                                                <div style={{ width: '40%' }}>{item.skillset}</div>
                                                <div style={{ width: '20%' }}>{item.experience}</div>
                                                <div style={{ width: '40%' }}>{item.qualification}</div>
                                            </summary>
                                            <div className="auk__accordion-body">{item.summary}</div>
                                        </details>
                                    ) : (
                                        // without summary
                                        <div key={item.id} className="auk__accordion">
                                            <div className="auk__accordion-body">
                                                <div style={{ width: '40%' }}>{item.skillset}</div>
                                                <div style={{ width: '20%' }}>{item.experience}</div>
                                                <div style={{ width: '40%' }}>{item.qualification}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>

            <PingModal
                open={pingModalOpen}
                userId={pingModalUserId}
                candidateName={profileOverview.profile.firstname}
                employerFirstName={profileData.firstname}
                employerSurname={profileData.surname}
                organisationName={profileData.organisationName}
                employerEmail={profileData.email}
                employerNumber={profileData.phoneNumber}
                onClose={() => setPingModalOpen(false)}
                readiness={profileOverview?.profile?.readiness}
            />
        </>
    );
}

export default Profile;
