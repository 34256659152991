import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import styled from 'styled-components';

import Account from './Containers/Pages/Account';
import AccountType from './Containers/Pages/AccountType';
import Profile from './Containers/Pages/Profile';
import ProfileOverview from './Containers/Pages/ProfileOverview';
import GeographicInfo from './Containers/Pages/GeographicInfo';
import CandidateSearch from './Containers/Pages/archive/CandidateSearch';
import CandidateSearchBeta from './Containers/Pages/CandidateSearchBeta';
import Shortlist from './Containers/Pages/archive/Shortlist';
import Landing from './Containers/Landing';
import AuthCallback from './Containers/AuthCallback';
import AdminSkills from './Containers/Pages/Admin/AdminSkills';
import AdminUsers from './Containers/Pages/Admin/AdminUsers';
import AdminUsersEdit from './Containers/Pages/Admin/AdminUsersEdit';
import AdminOrganisations from './Containers/Pages/Admin/AdminOrganisations';
import AdminDashboard from './Containers/Pages/Admin/AdminDashboard';

import Header from './components/navigation/Header';
import ProfileStepperLayout from './components/navigation/ProfileStepperLayout';
import NovoSnackbar from './components/NovoSnackbar';
import Logout from './components/navigation/Logout';

import { PrivateRoute } from './auth/AuthProvider';
import { useConfig } from './config/ConfigProvider';
import { selectProfileData } from './redux/selectors/profileSelector';
import { selectIsUserAdmin } from './redux/selectors/userSelector';

import JobBoard from './Containers/Pages/Jobs';
import JobDetails from './Containers/Pages/Jobs/JobDetails';
import JobList from './Containers/Pages/my-job/JobList';
import JobView from './Containers/Pages/my-job/JobView';
import JobClosed from './Containers/Pages/JobsClosed';

import './App.scss';
import ProfileDetail from './Containers/Pages/Profile/ProfileDetail';
import Skills from './Containers/Pages/Profile/Skills';
import Attachments from './Containers/Pages/Profile/Attachments';
import AdminJobs from './Containers/Pages/Admin/Jobs';
import JobProfile from './Containers/Pages/Jobs/JobDetails/Profile';
import Loader from './components/loader';
import TestPage from './Containers/Pages/Test';

import DemoPage from './Containers/Pages/Public/demo';
import PublicCandidate from './Containers/Pages/Public/candidate';
import PublicOrganization from './Containers/Pages/Public/organisation';

const FluidContainer = styled(Container)`
    padding: 1 1rem;
    max-height: calc(100vh - 3rem - 3rem);
    overflow: auto;
    margin-top: 1rem;
`;

function App() {
    const { config, error } = useConfig();
    const [configLoadingState, setConfigLoadingState] = useState<'loading' | 'ready' | 'error'>('loading');
    const isAdminUser = useSelector(selectIsUserAdmin);
    const profileData = useSelector(selectProfileData);

    // const wizardCompleted = useSelector(selectProfileWizardCompleted);

    useEffect(() => {
        if (error) {
            setConfigLoadingState('error');
            return;
        }

        if (config) {
            setConfigLoadingState('ready');
        }
    }, [config, error]);

    if (configLoadingState === 'loading') {
        return <Loader />;
    }

    if (configLoadingState === 'error') {
        return (
            <div>
                <p style={{ color: 'red', textAlign: 'center' }}>Error while fetching global config </p>
            </div>
        );
    }

    return (
        <>
            <Header />
            {/* <LeftNav /> */}
            <NovoSnackbar />

            <FluidContainer maxWidth="xl">
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/authCallback" element={<AuthCallback />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/public/demo" element={<DemoPage />} />
                    <Route path="/map" element={<TestPage />} />
                    <Route path="/jobs/:jobId" element={<JobDetails />} />
                    <Route path="/job-closed" element={<JobClosed />} />
                    <Route path="/candidate/:candidateId" element={<PublicCandidate />} />
                    <Route path="/organization/:organizationId" element={<PublicOrganization />} />

                    {Boolean(profileData.email) && (
                        <>
                            {/* Begin Seeker */}
                            <Route element={<ProfileStepperLayout />}>
                                <Route
                                    path="/account"
                                    element={
                                        <PrivateRoute>
                                            <Account />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/accountType"
                                    element={
                                        <PrivateRoute>
                                            <AccountType />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/Profile"
                                    element={
                                        <PrivateRoute>
                                            <ProfileDetail />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/skills"
                                    element={
                                        <PrivateRoute>
                                            <Skills />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/files"
                                    element={
                                        <PrivateRoute>
                                            <Attachments />
                                        </PrivateRoute>
                                    }
                                />

                                {profileData.accountType === 'jobseeker' || isAdminUser ? (
                                    <>
                                        <Route
                                            path="/my-profile"
                                            element={
                                                <PrivateRoute>
                                                    <Profile />
                                                </PrivateRoute>
                                            }
                                        />
                                        <Route
                                            path="/geographicInfo"
                                            element={
                                                <PrivateRoute>
                                                    <GeographicInfo />
                                                </PrivateRoute>
                                            }
                                        />
                                        <Route
                                            path="/jobs"
                                            element={
                                                <PrivateRoute>
                                                    <JobBoard />
                                                </PrivateRoute>
                                            }
                                        />
                                        <Route
                                            path="/jobs/:jobId"
                                            element={
                                                <PrivateRoute>
                                                    <JobDetails />
                                                </PrivateRoute>
                                            }
                                        />

                                        <Route
                                            path="/jobs/:jobId/profile"
                                            element={
                                                <PrivateRoute>
                                                    <JobProfile />
                                                </PrivateRoute>
                                            }
                                        />
                                    </>
                                ) : null}
                            </Route>

                            {/* End Seeker */}

                            {/* Begin Employer */}
                            {profileData.accountType === 'employer' || isAdminUser ? (
                                <>
                                    <Route
                                        path="/v1/candidate/search"
                                        element={
                                            <PrivateRoute>
                                                <CandidateSearch />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/employer/search"
                                        element={
                                            <PrivateRoute>
                                                <CandidateSearchBeta />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/shortlist"
                                        element={
                                            <PrivateRoute>
                                                <Shortlist />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/overview/:candidateId"
                                        element={
                                            <PrivateRoute>
                                                <ProfileOverview />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/my-jobs"
                                        element={
                                            <PrivateRoute>
                                                <JobList />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/my-jobs/:jobId"
                                        element={
                                            <PrivateRoute>
                                                <JobView />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/jobs"
                                        element={
                                            <PrivateRoute>
                                                <JobBoard />
                                            </PrivateRoute>
                                        }
                                    />

                                    <Route
                                        path="/jobs/:jobId/profile"
                                        element={
                                            <PrivateRoute>
                                                <JobProfile />
                                            </PrivateRoute>
                                        }
                                    />
                                </>
                            ) : null}
                            {/* End Employer */}

                            {/* Begin Admin */}
                            {isAdminUser && (
                                <>
                                    <Route
                                        path="/admin/"
                                        element={
                                            <PrivateRoute>
                                                <AdminDashboard />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/users"
                                        element={
                                            <PrivateRoute>
                                                <AdminUsers />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/users/edit/:userId"
                                        element={
                                            <PrivateRoute>
                                                <AdminUsersEdit />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/skills"
                                        element={
                                            <PrivateRoute>
                                                <AdminSkills />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/organisations"
                                        element={
                                            <PrivateRoute>
                                                <AdminOrganisations />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/Jobs"
                                        element={
                                            <PrivateRoute>
                                                <AdminJobs />
                                            </PrivateRoute>
                                        }
                                    />
                                </>
                            )}
                            {/* End Admin */}
                        </>
                    )}

                    {/* Default Route */}
                    <Route path="*" element={<Landing />} />
                </Routes>
            </FluidContainer>
        </>
    );
}

export default App;
