import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clone } from 'ramda';
import { ArrowForward } from '@mui/icons-material';
import {
    Typography,
    Divider,
    Grid,
    TextField,
    IconButton,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    OutlinedInput,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    Box,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormLabel,
    Autocomplete,
    Chip,
    CircularProgress,
    Link,
    ListSubheader,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MenuProps, CustomTooltip } from './../Profile/ProfileDetail';
import { Profile } from '../../../types/CandidateProfile';
import {
    seekingDropdown,
    profileReadiness,
    accountTypeDropdown,
    nationailityDropdown,
    securityClearanceDropdown,
    auCitizenshipStatusDropdown,
    countryDropdown,
    linkedInRequestDropDown,
} from '../../../data/dropDownData';
import { parseValue } from '../../../utils/parseValue';
import { capitalize } from '../../../utils/capitalize';
import { mapValueToLabel } from '../../../utils/mapValueToLabel';
import LocationSelector from '../../../components/LocationSelector';
import LocationSelectorGoogle from '../../../components/LocationSelectorGoogle';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon, Info as InfoIcon, Download as DownloadIcon } from '@mui/icons-material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getInstance } from '../../../services/fetch';
import CommonModal from '../../../components/CommonModal';
import { SkillsModalContent } from '../Profile/Skills';
import { LookupType, Skill } from '../../../types/skill';
import Toggle from '../../../components/Toggle';
import { SkillsAnswer } from '../../../types/answer';
import { useAdminSkillsQueryInfo } from '../../../hooks/useAdminSkillsQueryInfo';
import { adminDownloadFile } from '../../../redux/actions/adminAction';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../redux/actions/snackbarAction';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { useHistoryBack } from '../../../hooks/useHistoryBack';
import { formatUrl } from '../../../utils/url';
import useGetHighLevelSkills from '../../../hooks/useGetOneLevelSkills';

enum TabValue {
    PERSONAL_DETAILS = 'personal-details',
    SKILLS_AND_EXPERIENCE = 'skills-and-experience',
    ATTACHMENTS = 'attachments',
    ADMIN = 'admin',
}

type AdminField = 'accountType';
const ADMMIN_FIELDS: AdminField[] = ['accountType'];

declare interface MappedSkill {
    id: number;
    uuid: string;
    skillset: string;
    reference: string;
    qualification: string;
    experience: string;
    summary: string;
}

interface AutocompleteType {
    id: string;
    origId?: string;
    title?: string;
    level?: number;
    keywords?: any;
    lastChainElement: string;
}

const getKeywordsString = (keywords?: string[]) => {
    return keywords?.length ? `(${keywords.join(',')})` : '';
};

const AdminUsersEdit = () => {
    const { userId } = useParams();
    const queryClient = useQueryClient();
    const [tabValue, setTabValue] = useState(TabValue.PERSONAL_DETAILS);

    const handleChangeTab = (_: React.SyntheticEvent, newValue: TabValue) => {
        setTabValue(newValue);
    };

    const historyBack = useHistoryBack('/admin/users');
    const dispatch = useDispatch();

    const titleRef = useRef<HTMLDivElement | null>(null);

    const profileOverviewQuery = useQuery(
        ['profile-overview', userId],
        async () => {
            const response = await getInstance().get<{ data: Profile; error: string }>(`/admin/users/overview?userId=${userId}`);

            return response.data;
        },
        {
            enabled: !!userId,
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    const reduxUserOverview = profileOverviewQuery.data;
    const [userOverview, setUserOverview] = useState<Profile>();

    useEffect(() => {
        if (reduxUserOverview) {
            setUserOverview(clone(reduxUserOverview));
        }
    }, [reduxUserOverview, dispatch]);

    const handleUserOverviewChange = async (event: { target: { name: string; value: any; checked?: any } }) => {
        const { name, value } = event.target;

        const newVal = parseValue(value);
        const nameArr = name.split('.');
        let newUserOverview = {} as Profile;

        if (nameArr.length === 2) {
            const [parent, child] = nameArr;
            newUserOverview = {
                ...userOverview,
                [parent]: {
                    ...(userOverview as any)[parent],
                    [child]: newVal,
                },
            };
        } else {
            newUserOverview = {
                ...userOverview,
                [name]: newVal,
            };
        }
        setUserOverview(newUserOverview);
    };

    const scrollToTop = () => {
        if (titleRef && titleRef.current) {
            titleRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const upsertProfileMutation = useMutation(async (profile: Profile) => {
        try {
            await getInstance().put<{ error: string }, any>(`/seeker/profile`, { profile, adminEdit: true });
            await profileOverviewQuery.refetch();
            dispatch(showSuccessSnackbar('Saved successfully!'));
        } catch (e) {
            dispatch({
                type: 'PROFILE_UPDATE_ERROR',
                payload: getErrorMessage(e),
            });

            dispatch(showErrorSnackbar(`Profile upsert error`));
        }
    });

    const onSubmit = async () => {
        try {
            if (!userOverview || !reduxUserOverview) {
                return;
            }

            if (tabValue === TabValue.ADMIN) {
                const newUserOverview = clone(reduxUserOverview);
                ADMMIN_FIELDS.forEach((field) => {
                    newUserOverview[field] = userOverview[field];
                });
                newUserOverview.linkedInRequest = userOverview.linkedInRequest ?? false;
                newUserOverview.linkedInStatus = userOverview.linkedInStatus ?? '';
                await upsertProfileMutation.mutateAsync(newUserOverview);
                scrollToTop();
            }

            if (tabValue === TabValue.PERSONAL_DETAILS) {
                const newUserOverview = clone(userOverview);

                ADMMIN_FIELDS.forEach((field) => {
                    newUserOverview[field] = reduxUserOverview[field];
                });

                await upsertProfileMutation.mutateAsync(newUserOverview);
                scrollToTop();
            }
        } catch (error) {
            dispatch(showErrorSnackbar(`Profile upsert error`));
        }
    };

    const FILE_QUERY_KEY = ['files', userOverview?.userId];

    const filesQueryInfo = useQuery(
        FILE_QUERY_KEY,
        async () => {
            const response = await getInstance().get<any>(`/admin/users/files/${userOverview?.userId}`);

            if (response.error) {
                throw new Error(response.error);
            }

            return response.data;
        },
        {
            enabled: !!userOverview?.userId,
        }
    );

    const [filesToDelete, setFilesToDelete] = useState<string[]>([]);

    const deleteFileMutation = useMutation(async ({ uuid }: { uuid: string }) => {
        setFilesToDelete((v) => {
            return [...v, uuid];
        });

        try {
            await getInstance().delete<{ error: string }, any>(`/admin/users/files/${userOverview?.userId}/${uuid}`);
            queryClient.setQueryData(FILE_QUERY_KEY, (v: any) => {
                if (!v) {
                    return v;
                }

                return {
                    ...v,
                    files: v.files.filter((file: any) => {
                        return file.uuid !== uuid;
                    }),
                };
            });
        } catch (error) {
            setFilesToDelete((v) => {
                return v.filter((_uuid) => {
                    return _uuid !== uuid;
                });
            });
        }
    });

    const [selectedFiles, setSelectedFiles] = useState([]);

    const selectFile: any = (event: any) => {
        setSelectedFiles(event.target.files);
    };

    const uploadFileMutation = useMutation(async () => {
        const currentFile = selectedFiles[0];

        if (!userOverview?.userId || !currentFile) {
            return;
        }

        const formData = new FormData();
        formData.append('filename', (currentFile as any).name);
        formData.append('file', currentFile);

        await getInstance().post<{ error: string }, any>(`/admin/users/files/${userOverview?.userId}/upload`, formData, { isForm: true });

        setSelectedFiles([]);
        filesQueryInfo.refetch();
    });

    const onRemove = () => {
        setSelectedFiles([]);
    };

    const uploadedFiles = filesQueryInfo.data?.files || [];

    const [showSkillsModal, setShowSkillsModal] = useState(false);

    const skillAnswersQueryInfo = useQuery(
        ['skill-answers', userOverview?.userId],
        async () => {
            const response = await getInstance().get<any>(`/admin/skills/user/${userOverview?.userId}`);

            if (response.error) {
                throw new Error(response.error);
            }

            return response.data;
        },
        {
            enabled: !!userOverview?.userId,
        }
    );

    const skillsQueryInfo = useAdminSkillsQueryInfo();
    const skills =
        skillsQueryInfo.data?.filter((skill) => {
            return !!skill.enabled;
        }) || [];

    const memoizedSkills = useMemo(() => skills, [JSON.stringify(skills)]);

    const { levelOneSkills } = useGetHighLevelSkills(memoizedSkills);

    function getSkillFromRef(ref: string) {
        const skill = skills.find((skill: Skill) => skill.id === ref) || {};
        return skill;
    }

    const mappedSkills = Object.keys(skillAnswersQueryInfo.data || {}).map((key, idx) => {
        const skill = getSkillFromRef(key) as Skill;
        const answer = skillAnswersQueryInfo.data[key];
        const qualVal = (skill?.qualFramework || {})[answer.qualification || '']?.display || '';
        const expVal = (skill?.expFramework || {})[answer.experience || '']?.display || '';

        return {
            id: idx + 1,
            uuid: skill.uuid,
            skillset: skill.display,
            reference: key,
            qualification: answer.qualification,
            qualDisplay: qualVal,
            experience: answer.experience,
            expDisplay: expVal,
            summary: answer.summary,
        } as MappedSkill;
    });

    const [addEditModalOpen, setAddEditModalOpen] = useState(false);
    const [qualification, setQualification] = useState('');
    const [experience, setExperience] = useState('');
    const [summary, setSummary] = useState('');
    const [editMode, setEditMode] = useState(false);

    const handleAddEditClose = () => {
        setQualification('');
        setExperience('');
        setSummary('');
        setAddEditModalOpen(false);
        setEditMode(false);
        setSelectedSkill(undefined);
    };

    const [selectedSkill, setSelectedSkill] = useState<Skill>();
    const [selected, setSelected] = useState(['', '', '']);

    const handleOpenSkillAdd = () => {
        setSelected(['', '', '']);
        setAddEditModalOpen(true);
    };

    const enableSubmitButton = () => {
        if (!selectedSkill) {
            return false;
        }

        let EXISTS;
        if (selectedSkill?.qualFramework && selectedSkill?.expFramework) {
            EXISTS = 'BOTH';
        } else if (selectedSkill?.qualFramework) {
            EXISTS = 'QUAL';
        } else if (selectedSkill?.expFramework) {
            EXISTS = 'EXP';
        } else {
            EXISTS = 'NONE';
        }

        switch (EXISTS) {
            case 'BOTH':
                if (!qualification || !experience) {
                    return false;
                }
                if (qualification?.length > 0 && experience?.length > 0) {
                    return true;
                }
                break;
            case 'QUAL':
                if (qualification?.length > 0) {
                    return true;
                }
                break;
            case 'EXP':
                if (experience?.length > 0) {
                    return true;
                }
                break;
            case 'NONE':
                return true;
            default:
                return false;
        }
    };
    const [deleteSkill, setDeleteSkill] = useState<any>(null);

    function handleClickRemove(params: any) {
        if (skillAnswersMutation.isLoading) {
            return;
        }

        setDeleteSkill(params.row);
    }

    const handleConfirmClose = () => {
        setDeleteSkill(null);
    };

    function handleClickEdit(params: any) {
        setSelectedSkill(skills.find((skill) => skill.id === params.row.reference));
        setQualification(params.row.qualification);
        setExperience(params.row.experience);
        setSummary(params.row.summary);

        setEditMode(true);
        setTimeout(() => setAddEditModalOpen(true), 175);
    }

    function handleSkillDelete() {
        const _deleteSkill = deleteSkill;

        if (!_deleteSkill) {
            return;
        }

        setDeleteSkill(null);
        skillAnswersMutation.mutate({ [_deleteSkill.reference]: null });
    }

    function renderActions(params: GridRenderCellParams<any>) {
        return mappedSkills?.length >= 1 ? (
            <>
                <IconButton aria-label="Edit" onClick={() => handleClickEdit(params)}>
                    <EditIcon />
                </IconButton>

                <IconButton aria-label="Delete" onClick={() => handleClickRemove(params)}>
                    <DeleteIcon />
                </IconButton>
            </>
        ) : null;
    }

    const skillColumns = [
        { field: 'skillset', headerName: 'Skills & Experience', flex: 3 },
        { field: 'qualDisplay', headerName: ' Competency / Qualification Level', flex: 3 },
        { field: 'expDisplay', headerName: 'Experience', flex: 1 },
        { field: 'summary', headerName: 'Summary', sortable: false, flex: 4 },
        { field: 'actions', headerName: 'Actions', sortable: false, flex: 1, renderCell: renderActions },
    ];

    const [lookupMethod, setLookupMethod] = useState<LookupType>('KEYWORD' as LookupType);
    const [searchString, setSearchString] = useState<string>('');
    const [selectedAutoCompleteItem] = useState<AutocompleteType>();

    const skillAnswersMutation = useMutation(async (answers: SkillsAnswer) => {
        try {
            await getInstance().post<{ error: string }, any>(`/admin/skills/user/${userOverview?.userId}`, answers);
            skillAnswersQueryInfo.refetch();
        } catch (error) {
            //
        }
    });

    const handleSkillSubmit = async () => {
        let refText;
        if (editMode) {
            refText = (selectedSkill as any).id;
        } else {
            if (lookupMethod === 'HIERARCHY') {
                refText = selected[3];
            } else if (lookupMethod === 'KEYWORD') {
                refText = (selectedSkill as any).origId;
            }
        }

        const skillsAnswers = {
            [refText]: {
                qualification,
                experience,
                summary,
            },
        };

        handleAddEditClose();
        skillAnswersMutation.mutate(skillsAnswers);
    };

    const handleLookupChange = (_event: React.MouseEvent<HTMLElement> | null, newLookupMethod: LookupType) => {
        setLookupMethod(newLookupMethod);
        setQualification('');
        setExperience('');
        setSummary('');
        setSelectedSkill(undefined);
        setSelected(['', '', '']);
    };

    function selectSkilDropdown(level: number, value: string) {
        const newArr = [...selected];
        newArr[level] = value;
        for (let i = level + 1; i < 4; i++) {
            newArr[i] = '';
        }
        setQualification('');
        setExperience('');
        setSummary('');
        setSelected(newArr);
        setSelectedSkill(skills.find((skill) => skill.id === newArr[3]));
    }

    const mappedSkillsUuid = mappedSkills.map((mappedSkill) => mappedSkill.uuid);
    const filteredSkills = skills.filter((skill) => !mappedSkillsUuid.includes(skill.uuid || ''));

    const handleSkillChange = (event: any) => {
        const { name, value } = event.target;

        if (name === 'qualification') {
            setQualification(value);
        }
        if (name === 'experience') {
            setExperience(value);
        }
    };

    function compare(a: any, b: any) {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }

    function getLevel(chain: any) {
        const level = chain?.length;
        return level;
    }

    function getParents(item: Skill, source: Skill[]): any {
        if (item.parents && Array.isArray(item.parents) && item.parents.length > 0) {
            return item.parents
                .filter((p) => p !== item.id)
                .map((p) => {
                    const target = source.find((s) => s.id === p);
                    if (!target) {
                        return [[item.id]];
                    }
                    const parents = getParents(target, source);
                    return parents.map((newP: string[]) => [...newP, item.id]);
                })
                .flat();
        }
        return [[item.id]];
    }

    function getSeekerHelpText(id: string, source: Skill[]) {
        const helpText = source.find((s) => s.id === id)?.seekerHelpText;
        return helpText;
    }

    function compareTitle(a: any, b: any) {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    function getAutocomplete(source: Skill[]): AutocompleteType[] {
        const autoCompleteSkills = source
            .map((skill) => ({ ...skill, chain: getParents(skill, source) }))
            .map((skill: any) =>
                (skill.chain || []).map((chain: any, idx: number) => ({
                    chain,
                    id: `${skill.id}-${idx}`,
                    origId: skill.id,
                    qualFramework: skill.qualFramework,
                    expFramework: skill.expFramework,
                    keywords: skill.keywords,
                    display: skill.display,
                }))
            )
            .flat()
            .map((flatSkill) => ({
                ...flatSkill,
                id: flatSkill.origId,
                chain: undefined,
                title: flatSkill.display,
                lastChainElement: flatSkill.chain[flatSkill.chain.length - 1],
                level: getLevel(flatSkill.chain),
                seekerHelpText: getSeekerHelpText(flatSkill.origId, source),
            }))
            .filter((s) => s.level === 4);

        const uniqueAC = autoCompleteSkills.filter(
            (thing, index, self) =>
                index ===
                self.findIndex(
                    (t) =>
                        //unique on id
                        t.id === thing.id
                    // && t.title === thing.title
                )
        );
        return uniqueAC.sort(compareTitle);
    }

    const handleAutoCompleteChange = (value: AutocompleteType | null) => {
        const skillId = value?.lastChainElement;

        if (!skillId) {
            setSelectedSkill(undefined);
            return;
        }

        const selectedAutocompleteSkill = getAutocomplete(skills).find((skill) => skill.id === skillId);

        if (selectedAutoCompleteItem === undefined) {
            setQualification('');
            setExperience('');
            setSummary('');
        }

        setSelectedSkill(selectedAutocompleteSkill as unknown as Skill);
    };

    if (!userOverview) {
        return (
            <Grid sx={{ flexGrow: 1, pt: 8, pb: 2 }} container spacing={2} direction="row" display="flex" justifyContent="center">
                {profileOverviewQuery.isLoading ? (
                    <CircularProgress />
                ) : (
                    <div>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                            Error loading user
                        </Typography>
                    </div>
                )}
            </Grid>
        );
    }

    function handleLinkedInRequestChange(e: any) {
        const { checked: value } = e.target;
        setUserOverview({ ...userOverview, linkedInRequest: value });
    }

    return (
        <>
            <Typography variant="h4" p={2}>
                <IconButton
                    color="primary"
                    aria-label="back"
                    onClick={() => {
                        historyBack();
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>{' '}
                User Admin Edit
            </Typography>
            <Divider></Divider>

            <Box px={2} mb={2} ref={titleRef}>
                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example" centered>
                    <Tab label="Personal Details" value={TabValue.PERSONAL_DETAILS} />
                    <Tab label="Skills & Experience" value={TabValue.SKILLS_AND_EXPERIENCE} />
                    <Tab label="Attachments" value={TabValue.ATTACHMENTS} />
                    <Tab label="Admin" value={TabValue.ADMIN} />
                </Tabs>
            </Box>
            {tabValue === TabValue.PERSONAL_DETAILS && (
                <>
                    <Card variant="outlined" sx={{ ml: 2 }}>
                        <CardContent>
                            <Typography variant="h6" pl={3}>
                                Account Details
                            </Typography>
                            <Grid
                                sx={{ flexGrow: 1, pl: 3, pt: 2.25 }}
                                container
                                spacing={2}
                                padding={1}
                                alignContent="space-around"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item sm={12} md={6}>
                                    <TextField
                                        label="First Name"
                                        onChange={handleUserOverviewChange}
                                        name="firstname"
                                        value={userOverview.firstname}
                                        FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <TextField
                                        label="Last Name"
                                        onChange={handleUserOverviewChange}
                                        name="surname"
                                        value={userOverview.surname}
                                        FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{ flexGrow: 1, pl: 3, pt: 2.25 }}
                                container
                                spacing={2}
                                padding={1}
                                alignContent="space-around"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item sm={12} md={6}>
                                    <TextField
                                        label="Contact Email (Can be different to your login email) "
                                        onChange={handleUserOverviewChange}
                                        name="email"
                                        value={userOverview.email}
                                        FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <TextField
                                        label="Login Email"
                                        disabled
                                        name="email"
                                        value={userOverview.loginEmail}
                                        FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                sx={{ flexGrow: 1, pl: 3, pt: 2.25 }}
                                container
                                spacing={2}
                                padding={1}
                                alignContent="space-around"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item sm={12} md={6}>
                                    <TextField
                                        label="Phone Number"
                                        onChange={handleUserOverviewChange}
                                        name="phoneNumber"
                                        value={userOverview.phoneNumber}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <TextField label="Candidate ID" value={userOverview.candidateId} disabled fullWidth />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <br />
                    <Card variant="outlined" sx={{ ml: 2 }}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Typography variant="h6" pl={3}>
                                        Jobseeker Details
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{ flexGrow: 1, pl: 3, pt: 2.25 }}
                                container
                                spacing={2}
                                padding={1}
                                alignContent="space-around"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item sm={12}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Personal Details
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="auCitizenshipStatus-label" required>
                                            Australian Citizenship Status
                                        </InputLabel>
                                        <Select
                                            labelId="auCitizenshipStatus-label"
                                            name="auCitizenshipStatus"
                                            value={userOverview.auCitizenshipStatus}
                                            label="Australian Citizenship Status"
                                            onChange={handleUserOverviewChange}
                                        >
                                            {auCitizenshipStatusDropdown.map((data) => (
                                                <MenuItem key={data.key} value={data.value}>
                                                    {data.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="nationalities-label">Nationalities</InputLabel>
                                        <Select
                                            labelId="nationalities-label"
                                            name="nationality"
                                            multiple
                                            value={userOverview.nationality || []}
                                            onChange={handleUserOverviewChange}
                                            input={<OutlinedInput label="Nationalities" />}
                                            renderValue={(selected) => capitalize(selected.join(', '))}
                                            MenuProps={MenuProps}
                                        >
                                            {nationailityDropdown.map((data) => (
                                                <MenuItem key={data} value={data} sx={{ height: 36 }}>
                                                    <Checkbox checked={(userOverview.nationality || []).indexOf(data) > -1} />
                                                    <ListItemText primary={capitalize(data)} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12} md={4} style={{ position: 'relative' }}>
                                    {userOverview.linkedInUrl && (
                                        <Grid item xs={1} style={{ position: 'absolute', top: -9, right: 0 }}>
                                            <Link href={`${formatUrl(userOverview.linkedInUrl)}`} target="_blank">
                                                <ArrowForward />
                                            </Link>
                                        </Grid>
                                    )}
                                    <TextField
                                        label="LinkedIn URL"
                                        onChange={handleUserOverviewChange}
                                        name="linkedInUrl"
                                        placeholder="Copy and paste the full URL to your LinkedIn Profile"
                                        value={userOverview.linkedInUrl}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{ flexGrow: 1, pl: 3, pt: 2.5 }}
                                container
                                spacing={2}
                                padding={1}
                                alignContent="space-around"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item sm={12} p={0}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Current Location
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="country-label">Country</InputLabel>
                                        <Select
                                            labelId="country-label"
                                            name="location.country"
                                            value={userOverview.location?.country}
                                            label="Country"
                                            onChange={handleUserOverviewChange}
                                        >
                                            {countryDropdown.map((data) => (
                                                <MenuItem key={data} value={data}>
                                                    {data}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12} md={3}>
                                    <TextField
                                        label="City"
                                        onChange={handleUserOverviewChange}
                                        name="location.city"
                                        value={userOverview.location?.city}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={3}>
                                    <TextField
                                        label="State"
                                        onChange={handleUserOverviewChange}
                                        name="location.state"
                                        value={userOverview.location?.state}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={3}>
                                    <TextField
                                        label="Postcode"
                                        onChange={handleUserOverviewChange}
                                        name="location.postcode"
                                        value={userOverview.location?.postcode}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} p={0}>
                                    <Typography variant="body1" fontWeight={500}>
                                        geonames.org
                                    </Typography>
                                </Grid>
                                <LocationSelector />
                                <Grid item sm={12} p={0}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Google Maps API
                                    </Typography>
                                </Grid>
                                <LocationSelectorGoogle />
                                <Grid item sm={12} p={0}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Career Summary & Aspirations
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={9}>
                                    <TextField
                                        name="careerSummary"
                                        value={userOverview.careerSummary}
                                        onChange={handleUserOverviewChange}
                                        label="Career Summary & Aspirations"
                                        placeholder="We recommend one paragraph on what you have done, and one pararaph on what you would like to do"
                                        rows={3}
                                        inputProps={{ maxLength: 1000 }}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                                <Grid item md />
                            </Grid>

                            <Grid
                                sx={{ flexGrow: 1, pl: 3, pt: 2.5 }}
                                container
                                spacing={2}
                                padding={1}
                                alignContent="space-around"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item sm={12} p={0}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Employment Models
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={4.5}>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="seeking-label"
                                            name="seeking"
                                            value={userOverview.seeking || []}
                                            onChange={handleUserOverviewChange}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                if (selected && selected.length === 0) {
                                                    return (
                                                        <Typography variant="grayText">
                                                            List all of the employment models you would be prepared to consider
                                                        </Typography>
                                                    );
                                                }
                                                return selected.join(', ');
                                            }}
                                            MenuProps={MenuProps}
                                            multiple
                                            displayEmpty
                                        >
                                            <MenuItem disabled value="">
                                                <Typography variant="grayText">List all of the employment models you would be prepared to consider</Typography>
                                            </MenuItem>
                                            {seekingDropdown.map((value, key) => (
                                                <MenuItem key={key} value={value} sx={{ height: 36 }}>
                                                    <Checkbox checked={(userOverview.seeking || []).indexOf(value) > -1} />
                                                    <ListItemText primary={value} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md />
                            </Grid>
                            <Grid
                                sx={{ flexGrow: 1, pl: 3, pt: 2.5 }}
                                container
                                spacing={2}
                                padding={1}
                                alignContent="space-around"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item sm={12} p={0}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Readiness
                                    </Typography>
                                    <Typography variant="body2">How quickly can you move?</Typography>
                                </Grid>
                                <Grid item sm={12} md={3}>
                                    <Typography variant="body1">Jobseeking Status *</Typography>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <RadioGroup
                                        row
                                        onChange={handleUserOverviewChange}
                                        name="readiness"
                                        value={userOverview.readiness}
                                        sx={{ flexDirection: 'column' }}
                                    >
                                        {profileReadiness.map((record, idx) => {
                                            const label = (
                                                <Typography>
                                                    <b>{record.label}</b>{' '}
                                                    <em>
                                                        ({record.sub} <b>{record.keyword}</b>)
                                                    </em>
                                                </Typography>
                                            );
                                            return (
                                                <CustomTooltip key={idx} title={record.hover} placement="top">
                                                    <FormControlLabel value={record.value} control={<Radio />} label={label} />
                                                </CustomTooltip>
                                            );
                                        })}
                                    </RadioGroup>
                                </Grid>
                                <Grid item md={3} display={{ sm: 'none', md: 'block' }} />

                                {/* Row 7.3 */}
                                <Grid item sm={12} md={3}>
                                    <Typography variant="body1">Availability</Typography>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <TextField
                                        name="availabilityComment"
                                        placeholder="Comment on when you could start, how many weeks notice you will need to provide your existing employer, outstanding leave plans etc."
                                        value={userOverview.availabilityComment}
                                        onChange={handleUserOverviewChange}
                                        inputProps={{ maxLength: 250 }}
                                        rows={4}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                                <Grid item md={3} display={{ sm: 'none', md: 'block' }} />

                                {/* Row 7.4 */}
                                <Grid item sm={12} md={3}>
                                    <Typography variant="body1">Mobility</Typography>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <TextField
                                        name="mobilityComment"
                                        placeholder="Comment on your ability to travel or relocate for potential roles."
                                        value={userOverview.mobilityComment}
                                        onChange={handleUserOverviewChange}
                                        inputProps={{ maxLength: 250 }}
                                        rows={4}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{ flexGrow: 1, pl: 3, pt: 2.5 }}
                                container
                                spacing={2}
                                padding={1}
                                alignContent="space-around"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item sm={12} p={0}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Security Clearances
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={3}>
                                    <Typography variant="body1">Australian Security Clearances</Typography>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="securityClearance-label">Australian Security Clearances</InputLabel>
                                        <Select
                                            labelId="securityClearance-label"
                                            name="securityClearance"
                                            value={
                                                Array.isArray(userOverview.securityClearance)
                                                    ? userOverview.securityClearance
                                                    : [userOverview.securityClearance]
                                            }
                                            onChange={handleUserOverviewChange}
                                            input={<OutlinedInput label="Australian Security Clearances" />}
                                            renderValue={(selected) => {
                                                if (Array.isArray(selected)) {
                                                    return selected.map((value) => mapValueToLabel(securityClearanceDropdown, value as string)).join(', ');
                                                }

                                                return selected;
                                            }}
                                            MenuProps={MenuProps}
                                            multiple
                                        >
                                            {securityClearanceDropdown.map((data, key) => (
                                                <MenuItem key={key} value={data.value} sx={{ height: 36 }}>
                                                    <Checkbox checked={(userOverview.securityClearance || []).indexOf(data.value) > -1} />
                                                    <ListItemText primary={data.label} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md />
                            </Grid>
                            <Grid
                                sx={{ flexGrow: 1, pl: 3, pt: 2.5 }}
                                container
                                spacing={2}
                                padding={1}
                                alignContent="space-around"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item sm={12} p={0}>
                                    <Typography variant="body1" fontWeight={500}>
                                        Affirmative Action (Optional)
                                    </Typography>
                                    <Typography variant="body2">
                                        Our search engine allows jobseekers from minority groups to be targeted, but not excluded..
                                    </Typography>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <Typography variant="body1">Gender</Typography>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <RadioGroup onChange={handleUserOverviewChange} row name="gender" value={userOverview.gender}>
                                        <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                                        <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                                        {/* <FormControlLabel value="OTHER" control={<Radio />} label="Other" /> */}
                                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio />} label="Prefer not to say" />
                                    </RadioGroup>
                                </Grid>

                                {/* Row 9.3 */}
                                <Grid item sm={12} md={6}>
                                    <Typography variant="body1">I am a military veteran of Australia, UK, US, Canada or NZ?</Typography>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <RadioGroup onChange={handleUserOverviewChange} row name="veteranStatus" value={userOverview.veteranStatus}>
                                        <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="NO" control={<Radio />} label="No" />
                                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio />} label="Prefer not to say" />
                                    </RadioGroup>
                                </Grid>

                                {/* Row 9.4 */}
                                <Grid item sm={12} md={6}>
                                    <Typography variant="body1">I am a partner or family member of a serving ADF member?</Typography>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <RadioGroup onChange={handleUserOverviewChange} row name="partnerOrFamily" value={userOverview.partnerOrFamily}>
                                        <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="NO" control={<Radio />} label="No" />
                                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio />} label="Prefer not to say" />
                                    </RadioGroup>
                                </Grid>

                                {/* Row 9.5 */}
                                <Grid item sm={12} md={6}>
                                    <Typography variant="body1">I am returning to the workforce from a prolonged absence due to caring activities?</Typography>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <RadioGroup onChange={handleUserOverviewChange} row name="returningToWorkforce" value={userOverview.returningToWorkforce}>
                                        <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="NO" control={<Radio />} label="No" />
                                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio />} label="Prefer not to say" />
                                    </RadioGroup>
                                </Grid>

                                {/* Row 9.6 */}
                                <Grid item sm={12} md={6}>
                                    <Typography variant="body1">I am an Aboriginal or Torres Strait Islander?</Typography>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <RadioGroup
                                        onChange={handleUserOverviewChange}
                                        row
                                        name="aboriginalOrTorresStrait"
                                        value={userOverview.aboriginalOrTorresStrait}
                                    >
                                        <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="NO" control={<Radio />} label="No" />
                                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio />} label="Prefer not to say" />
                                    </RadioGroup>
                                </Grid>

                                {/* Row 9.7 */}
                                <Grid item sm={12} md={6}>
                                    <Typography variant="body1">I have a pre-existing condition that may require additional workplace support?</Typography>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <RadioGroup onChange={handleUserOverviewChange} row name="preExistingConditions" value={userOverview.preExistingConditions}>
                                        <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="NO" control={<Radio />} label="No" />
                                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio />} label="Prefer not to say" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Grid
                        sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                        container
                        spacing={2}
                        padding={1}
                        alignContent="flex-end"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item sm={1}>
                            <Button onClick={onSubmit} variant="contained" disabled={upsertProfileMutation.isLoading}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}

            {tabValue === TabValue.SKILLS_AND_EXPERIENCE && (
                <>
                    {skillsQueryInfo.isLoading || skillAnswersQueryInfo.isLoading ? (
                        <Grid item xs={12} sx={{ justifyContent: 'center', minHeight: 200, alignItems: 'center', display: 'flex' }}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12} ml={2}>
                                <Grid item xs={12}>
                                    <Button onClick={handleOpenSkillAdd} variant="contained" sx={{ margin: '10px', marginLeft: '0px' }}>
                                        Add Skill or Experience
                                    </Button>
                                    <IconButton
                                        aria-label="info"
                                        onClick={() => {
                                            setShowSkillsModal(true);
                                        }}
                                        color="primary"
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                </Grid>

                                <DataGrid
                                    checkboxSelection={false}
                                    disableSelectionOnClick
                                    rows={mappedSkills}
                                    columns={skillColumns}
                                    disableExtendRowFullWidth={false}
                                    disableColumnFilter
                                    disableColumnSelector
                                    autoHeight={true}
                                    hideFooter
                                    rowHeight={38}
                                />
                            </Grid>

                            <CommonModal
                                open={showSkillsModal}
                                buttonLabel="I understand"
                                title="TELL US ABOUT YOUR SKILLS, EXPERIENCE & ASPIRATIONS"
                                handleClick={() => {
                                    setShowSkillsModal(false);
                                }}
                            >
                                <SkillsModalContent />
                            </CommonModal>
                        </>
                    )}
                </>
            )}

            {tabValue === TabValue.ATTACHMENTS && (
                <Card variant="outlined" sx={{ ml: 2, p: 2 }}>
                    <Grid>
                        <Grid item xs={8}>
                            <Button variant="contained" component="label">
                                Step 1 - Select File
                                <input hidden type="file" onChange={selectFile} key={selectedFiles.length} />
                            </Button>
                            <label htmlFor="btn-upload"></label>
                        </Grid>
                        <Grid item xs={4} />

                        {/* Row 2 */}
                        <Grid item xs={8}>
                            <span>{selectedFiles && selectedFiles.length > 0 ? (selectedFiles[0] as any).name : null}</span>
                            {selectedFiles && selectedFiles.length > 0 ? (
                                <IconButton aria-label="delete" onClick={onRemove}>
                                    <DeleteIcon />
                                </IconButton>
                            ) : null}
                        </Grid>
                        <Grid item xs={4} />

                        {/* Row 3 */}
                        <Grid item xs={8} mt={4}>
                            <Button
                                variant="contained"
                                disabled={!(selectedFiles.length > 0) || uploadFileMutation.isLoading}
                                onClick={() => {
                                    uploadFileMutation.mutate();
                                }}
                            >
                                Step 2 - Upload File
                            </Button>
                        </Grid>
                        <Grid item xs={4} />
                    </Grid>

                    <Typography variant="h6" className="list-header" mt={6}>
                        List of Uploaded Files
                    </Typography>
                    <Grid sx={{ flexGrow: 1 }} container spacing={1} padding={1} justifyContent="flex-start" alignItems="center">
                        <List>
                            {uploadedFiles.map((file: any, idx: number) => (
                                <React.Fragment key={idx}>
                                    <ListItem>
                                        <ListItemText primary={(file as any).name} />
                                        <Button
                                            onClick={() => {
                                                if (!userOverview?.userId) {
                                                    return;
                                                }

                                                dispatch(adminDownloadFile(userOverview.userId, (file as any).uuid));
                                            }}
                                            variant="outlined"
                                            sx={{ m: 1 }}
                                            startIcon={<DownloadIcon />}
                                        >
                                            Download
                                        </Button>
                                        <Button
                                            disabled={filesToDelete.includes(file.uuid)}
                                            onClick={() => {
                                                deleteFileMutation.mutate({
                                                    uuid: file.uuid,
                                                });
                                            }}
                                            variant="outlined"
                                            sx={{ m: 1 }}
                                            startIcon={<DeleteIcon />}
                                        >
                                            Remove
                                        </Button>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                    </Grid>
                </Card>
            )}

            {tabValue === TabValue.ADMIN && (
                <>
                    <Card variant="outlined" sx={{ ml: 2 }}>
                        <CardContent>
                            <Typography variant="h6" pl={3}>
                                Admin Settings
                            </Typography>
                            <Grid
                                sx={{ flexGrow: 1, pl: 3, pt: 2.25 }}
                                container
                                spacing={2}
                                padding={1}
                                alignContent="space-around"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="accountType-label" required>
                                            Account Type
                                        </InputLabel>
                                        <Select
                                            labelId="accountType-label"
                                            name="accountType"
                                            value={userOverview.accountType}
                                            label="Account Type"
                                            onChange={handleUserOverviewChange}
                                        >
                                            {accountTypeDropdown.map((data) => (
                                                <MenuItem key={data.key} value={data.value}>
                                                    {capitalize(data.value)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <TextField label="Last Updated" name="lastUpdated" value={userOverview.lastUpdated} disabled fullWidth />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <TextField label="Wizard Completed" name="wizardCompleted" value={userOverview.wizardCompleted} disabled fullWidth />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <TextField
                                        label="Requested Employer Access (employer only)"
                                        name="requestedEmployerAccess"
                                        value={userOverview.requestedEmployerAccess}
                                        disabled
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={userOverview.linkedInRequest ?? false} onChange={handleLinkedInRequestChange} />}
                                        label="LinkedIn connection has been requested"
                                    />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="Linkedin-status">LinkedIn status</InputLabel>
                                        <Select
                                            labelId="Linkedin-status"
                                            name="linkedInStatus"
                                            value={userOverview.linkedInStatus}
                                            label="Account Type"
                                            onChange={handleUserOverviewChange}
                                        >
                                            {linkedInRequestDropDown.map((data) => (
                                                <MenuItem key={data.key} value={data.value}>
                                                    {data.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Grid
                        sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                        container
                        spacing={2}
                        padding={1}
                        alignContent="flex-end"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item sm={1}>
                            <Button onClick={onSubmit} variant="contained">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}

            <Dialog open={addEditModalOpen} onClose={handleAddEditClose} fullWidth maxWidth={'md'}>
                {editMode ? (
                    <DialogTitle sx={{ textTransform: 'capitalize' }}>
                        Edit Skillset <br />
                        <Typography component="span" variant="h6" sx={{ fontStyle: 'italic', textTransform: 'capitalize' }}>
                            {selectedSkill?.display?.toLowerCase()}
                        </Typography>
                    </DialogTitle>
                ) : (
                    <DialogTitle>Add a new skill or experience to my profile</DialogTitle>
                )}

                <DialogContent style={{ minHeight: '220px' }}>
                    <Grid sx={{ flexGrow: 1 }} container spacing={2} alignItems="center">
                        {!editMode && (
                            <>
                                <Grid item xs={12} p={0}>
                                    <div style={{ width: 500 }}>
                                        <Toggle
                                            value={lookupMethod}
                                            options={[
                                                {
                                                    value: 'KEYWORD',
                                                    label: 'FIND BY KEYWORD',
                                                },
                                                {
                                                    value: 'HIERARCHY',
                                                    label: 'FIND WITHIN HIERARCHY',
                                                },
                                            ]}
                                            onValueChange={(newValue) => {
                                                handleLookupChange(null, newValue as LookupType);
                                            }}
                                        />
                                    </div>
                                    <br />
                                </Grid>
                                <Grid item xs={12} p={0}>
                                    <Divider></Divider>
                                </Grid>
                            </>
                        )}

                        {!editMode && lookupMethod === 'HIERARCHY' && (
                            <>
                                <Grid item xs={3}>
                                    <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                        <InputLabel variant="outlined" htmlFor="select-industry">
                                            Level 1
                                        </InputLabel>
                                        <Select
                                            label="Industry"
                                            name="industry"
                                            onChange={(event) => selectSkilDropdown(0, event.target.value)}
                                            value={selected[0]}
                                            inputProps={{ id: 'select-industry' }}
                                        >
                                            {/* batch 2  changes ===> 11 march */}
                                            {/* {skills
                                                .filter((skill) => (skill.parents || []).length === 0)
                                                .map((record, idx) => (
                                                    <MenuItem key={idx} value={record.id}>
                                                        {record.display}
                                                    </MenuItem>
                                                ))} */}
                                            {levelOneSkills.map((item) => {
                                                if (item.group) {
                                                    return (
                                                        <ListSubheader
                                                            color="primary"
                                                            key={item.group}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                backgroundColor: '#1b717a',
                                                                color: 'white',
                                                                pointerEvents: 'none',
                                                            }}
                                                        >
                                                            <Typography variant="body1">{item.group}</Typography>
                                                        </ListSubheader>
                                                    );
                                                } else {
                                                    return (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.display}
                                                        </MenuItem>
                                                    );
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    {selected[0] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-category">
                                                Level 2
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="Category"
                                                name="category"
                                                onChange={(event) => selectSkilDropdown(1, event.target.value)}
                                                value={selected[1]}
                                                inputProps={{ id: 'select-category' }}
                                            >
                                                {skills
                                                    .filter((skill) => skill.parents?.includes(selected[0]))
                                                    .map((record, idx) => (
                                                        <MenuItem key={idx} value={record.id}>
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    {selected[1] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-subcategory">
                                                Level 3
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="Sub-category"
                                                name="subcategory"
                                                onChange={(event) => selectSkilDropdown(2, event.target.value)}
                                                value={selected[2]}
                                                inputProps={{ id: 'select-subcategory' }}
                                            >
                                                {skills
                                                    .filter((skill) => skill.parents?.includes(selected[1]))
                                                    .map((record, idx) => (
                                                        <MenuItem key={idx} value={record.id}>
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    {selected[2] && (
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 120, maxWidth: 300 }}>
                                            <InputLabel variant="outlined" htmlFor="select-skillset">
                                                SKILL or EXPERIENCE
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="SKILL or EXPERIENCE"
                                                name="skillset"
                                                onChange={(event) => selectSkilDropdown(3, event.target.value)}
                                                value={selected[3]}
                                                inputProps={{ id: 'select-skillset' }}
                                            >
                                                {filteredSkills
                                                    .filter((skill) => skill.parents?.includes(selected[2]))
                                                    .map((record, idx) => (
                                                        <MenuItem key={idx} value={record.id}>
                                                            {record.display}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item xs={12} p={0}>
                                    <Divider></Divider>
                                </Grid>
                            </>
                        )}

                        {!editMode && lookupMethod === 'KEYWORD' && (
                            <>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" fullWidth>
                                        <FormLabel component="legend">Skill or Experience:</FormLabel>
                                        <Autocomplete
                                            loadingText="Type a keyword to find associated skills & experiences"
                                            loading={!searchString?.trim()}
                                            inputValue={searchString}
                                            filterOptions={searchString?.trim() ? undefined : () => []}
                                            onInputChange={(event, value) => {
                                                setSearchString(value);
                                            }}
                                            id="auto-complete"
                                            options={getAutocomplete(filteredSkills)}
                                            getOptionLabel={(option: AutocompleteType) => `${option.title}`}
                                            renderOption={(props, option) => (
                                                <li
                                                    {...props}
                                                    key={option.id}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        minHeight: 36,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            flexWrap: 'wrap',
                                                            rowGap: '0.25rem',
                                                            columnGap: '0.25rem',
                                                        }}
                                                    >
                                                        <Typography style={{ fontSize: '1rem', marginRight: 16 }}>{option.title}</Typography>
                                                        {/* {option.keywords.map((item: any, i: number) => (
                                                            <Chip key={i} label={`${item}`} style={{ padding: 0, height: '1.5rem', pointerEvents: 'none' }} />
                                                        ))} */}
                                                    </div>
                                                </li>
                                            )}
                                            autoComplete={true}
                                            includeInputInList
                                            onChange={(event, value) => {
                                                handleAutoCompleteChange(value);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // label='Search Criteria'
                                                    variant="filled"
                                                    sx={{ m: 0, minWidth: 250 }}
                                                    autoFocus
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        )}
                    </Grid>

                    <Grid sx={{ flexGrow: 1 }} container spacing={2} justifyContent="flex-start" alignItems="flex-start">
                        {(selectedSkill || selected[3]) && (
                            <>
                                {selectedSkill?.seekerHelpText && (
                                    <Grid item xs={12}>
                                        <br />
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{selectedSkill?.seekerHelpText}</FormLabel>
                                        </FormControl>
                                    </Grid>
                                )}

                                {selectedSkill?.qualFramework && (
                                    <Grid item xs={6}>
                                        <h3>Level</h3>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">What is your highest level</FormLabel>

                                            <RadioGroup name="qualification" onChange={handleSkillChange} value={qualification}>
                                                {Object.keys(selectedSkill?.qualFramework || {})
                                                    .sort(compare)
                                                    .map((key, idx) => (
                                                        <FormControlLabel
                                                            sx={{ mt: 2 }}
                                                            key={idx}
                                                            value={key}
                                                            control={<Radio />}
                                                            label={(selectedSkill?.qualFramework || {})[key].display}
                                                        />
                                                    ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                )}

                                {selectedSkill?.expFramework && (
                                    <Grid item xs={6}>
                                        <h3>Experience</h3>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">
                                                How many years cumulative experience do you have studying and/or directly applying this skillset
                                            </FormLabel>
                                            <RadioGroup name="experience" onChange={handleSkillChange} value={experience}>
                                                {Object.keys(selectedSkill?.expFramework || {}).map((key, idx) => (
                                                    <FormControlLabel
                                                        key={idx}
                                                        value={key}
                                                        control={<Radio />}
                                                        label={(selectedSkill?.expFramework || {})[key].display || ''}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item xs={12} p={0}>
                                    <Divider></Divider>
                                </Grid>

                                <Grid item xs={12}>
                                    <h3>Additional details:</h3>
                                    <p>
                                        Provide additional details of qualifications, experience, licences, certifications, professional association memberships
                                        etc. directly related to this skillset. Feel free to add details of personal aspirations and other messages you would
                                        like potential employers to be aware of. Tell your full story. (500 words)
                                    </p>
                                    <TextField
                                        multiline
                                        fullWidth
                                        value={summary}
                                        label="Additional details"
                                        id="fullWidth"
                                        rows={5}
                                        onChange={(event) => setSummary(event.target.value)}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>

                    <br />

                    <DialogActions style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                        <Button onClick={handleAddEditClose} variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={handleSkillSubmit} disabled={!enableSubmitButton()} variant="contained">
                            {editMode ? 'Save' : 'Add Skills And Experience'}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <Dialog open={!!deleteSkill} onClose={handleConfirmClose} fullWidth maxWidth="sm">
                <DialogTitle>Confirm delete</DialogTitle>
                <Box position="absolute" top={2} right={2}>
                    <IconButton onClick={handleConfirmClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <p>
                        Are you sure you want to delete the <b>{deleteSkill?.skillset}</b> skillset?
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleConfirmClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSkillDelete}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AdminUsersEdit;
