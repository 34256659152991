import React, { useState, useEffect, useRef, MouseEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { clone } from 'ramda';
import {
    Typography,
    TextField,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Grid,
    Divider,
    InputLabel,
    FormControl,
    FormHelperText,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CommonModal from '../../../components/CommonModal';
import { Profile as CandidateProfile } from '../../../types/CandidateProfile';
import { LocationState } from '../../../types/location';
import { setActiveStep, setCongratsMessage, upsertProfile } from '../../../redux/actions/profileAction';
import { getSkillsAnswers } from '../../../redux/actions/skillsAction';
import { getFiles } from '../../../redux/actions/filesAction';
import { showErrorSnackbar } from '../../../redux/actions/snackbarAction';
import { selectProfileData, selectProfileCongratsMessage } from '../../../redux/selectors/profileSelector';
import {
    profileReadiness,
    securityClearanceDropdown,
    seekingDropdown,
    nationailityDropdown,
    countryDropdown,
    auCitizenshipStatusDropdown,
    workingArrangementDropdown,
} from '../../../data/dropDownData';
import { PROFILE_STEPS } from '../../../data/profileStepperData';
import { capitalize } from '../../../utils/capitalize';
import { mapValueToLabel } from '../../../utils/mapValueToLabel';
import { parseValue } from '../../../utils/parseValue';
import { LIST_MAP, HELPER_TEXT } from '../../../fixtures';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const pages = PROFILE_STEPS.map((step) => step.page);
const activeStep = pages.indexOf('/profile') + 1;
const nextPage = pages[activeStep];
const previousPage = pages[activeStep - 2];

export const REQUIRED_FIELDS_MAP = {
    readiness: false,
    auCitizenshipStatus: false,
    firstname: false,
    surname: false,
    email: false,
};

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: '0.875rem',
    },
}));

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function Profile() {
    const titleRef = useRef<HTMLDivElement | null>(null);
    const statusRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reduxProfileDetails = useSelector(selectProfileData);
    const congratsMessage = useSelector(selectProfileCongratsMessage);
    const [open, setOpen] = useState<boolean>(congratsMessage);
    const [profileDetails, setProfileDetails] = useState<CandidateProfile>(reduxProfileDetails);
    const [requiredFields, setRequiredFields] = useState<typeof REQUIRED_FIELDS_MAP>(REQUIRED_FIELDS_MAP);

    const state = location.state as LocationState;

    useEffect(() => {
        setProfileDetails(clone(reduxProfileDetails));
        setOpen(clone(congratsMessage));
    }, [congratsMessage, reduxProfileDetails, setOpen, setProfileDetails]);

    useEffect(() => {
        dispatch(getSkillsAnswers());
        dispatch(getFiles());
    }, [dispatch]);

    useEffect(() => {
        if (state?.authProvider && !profileDetails.wizardCompleted) {
            navigate('/account');
        }
    }, [state, profileDetails.wizardCompleted, navigate]);

    const scrollToTop = () => {
        if (titleRef && titleRef.current) {
            titleRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToStatus = () => {
        if (statusRef && statusRef.current) {
            statusRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const onPrevious = () => {
        navigate(previousPage);
    };

    const onSubmit = () => {
        const { readiness, auCitizenshipStatus, firstname, surname, email } = profileDetails;
        const message = (
            <div>
                Your profile has been saved as <b>{mapValueToLabel(profileReadiness, profileDetails.readiness || '')}</b>
                <ul>
                    <li>Your skills - {LIST_MAP[profileDetails.readiness as keyof typeof LIST_MAP].skills}</li>
                    <li>Your identity - {LIST_MAP[profileDetails.readiness as keyof typeof LIST_MAP].identity}</li>
                </ul>
            </div>
        );

        if (!auCitizenshipStatus || readiness === 'EMPTY') {
            setRequiredFields({
                auCitizenshipStatus: !auCitizenshipStatus,
                readiness: readiness === 'EMPTY',
                firstname: !firstname,
                surname: !surname,
                email: !email,
            });

            dispatch(showErrorSnackbar('Mandatory fields missing!'));

            if (!auCitizenshipStatus) {
                scrollToTop();
            } else {
                scrollToStatus();
            }

            return;
        }

        const payload = {
            ...profileDetails,
        };

        dispatch(upsertProfile(payload, false, message));

        setRequiredFields(REQUIRED_FIELDS_MAP);

        if (!profileDetails.wizardCompleted) {
            dispatch(setActiveStep(activeStep));
            navigate(nextPage);
        } else {
            scrollToTop();
        }
    };

    const handleProfileDetailsChange = (event: { target: { name: string; value: any; checked?: any } }) => {
        const { name, value } = event.target;

        const newVal = parseValue(value);
        const nameArr = name.split('.');
        let newProfileDetails = {};
        if (nameArr.length === 2) {
            const [parent, child] = nameArr;
            newProfileDetails = {
                ...profileDetails,
                [parent]: {
                    ...(profileDetails as any)[parent],
                    [child]: newVal,
                },
            };
        } else {
            newProfileDetails = {
                ...profileDetails,
                [name]: newVal,
            };
        }
        setProfileDetails(newProfileDetails);
    };

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event: any) => {
        const { name, value } = event.target;

        let newProfileDetails = {};

        if (value === profileDetails[name as keyof typeof profileDetails]) {
            newProfileDetails = {
                ...profileDetails,
                [name]: '',
            };
        } else {
            newProfileDetails = {
                ...profileDetails,
                [name]: value,
            };
        }

        setProfileDetails(newProfileDetails);
    };

    const handleCongratsModal = () => {
        setOpen(false);
        dispatch(setCongratsMessage(false));
    };

    return (
        <>
            {profileDetails.wizardCompleted && (
                <Grid xs={12} p={3}>
                    <Typography variant="h4" ref={titleRef}>
                        Account Details
                    </Typography>
                </Grid>
            )}
            <Divider></Divider>
            <Grid sx={{ flexGrow: 1, pl: 3, pt: 4 }} container spacing={2} alignContent="space-around" justifyContent="flex-start" alignItems="center">
                <Grid
                    item
                    sx={{ flexGrow: 1, pt: 2.25 }}
                    container
                    spacing={2}
                    padding={1}
                    alignContent="space-around"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="First Name"
                            onChange={handleProfileDetailsChange}
                            name="firstname"
                            value={profileDetails.firstname}
                            error={requiredFields.firstname}
                            helperText={requiredFields.firstname ? HELPER_TEXT : ''}
                            FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="Last Name"
                            onChange={handleProfileDetailsChange}
                            name="surname"
                            value={profileDetails.surname}
                            error={requiredFields.surname}
                            helperText={requiredFields.surname ? HELPER_TEXT : ''}
                            FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    sx={{ flexGrow: 1, pt: 2.25 }}
                    container
                    spacing={2}
                    padding={1}
                    alignContent="space-around"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="Contact Email (Can be different to your login email) "
                            onChange={handleProfileDetailsChange}
                            name="email"
                            value={profileDetails.email}
                            error={requiredFields.email}
                            helperText={requiredFields.email ? HELPER_TEXT : ''}
                            FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="Login Email"
                            name="email"
                            disabled
                            value={profileDetails.loginEmail}
                            helperText={requiredFields.email ? HELPER_TEXT : ''}
                            FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    sx={{ flexGrow: 1, pt: 2.25 }}
                    container
                    spacing={2}
                    padding={1}
                    alignContent="space-around"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item sm={12} md={6}>
                        <TextField label="Phone Number" onChange={handleProfileDetailsChange} name="phoneNumber" value={profileDetails.phoneNumber} fullWidth />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField label="Candidate ID" value={profileDetails.candidateId} disabled fullWidth />
                    </Grid>
                </Grid>
            </Grid>
            <br />
            {profileDetails.wizardCompleted && (
                <Grid xs={12} px={3}>
                    <Typography variant="h4">Jobseeker Details</Typography>
                </Grid>
            )}
            <br />
            <Divider></Divider>
            <br />
            <Grid
                sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                container
                spacing={2}
                padding={1}
                alignContent="space-around"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Typography variant="h5">Personal Details</Typography>
                </Grid>

                <Grid item sm={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="auCitizenshipStatus-label" required>
                            Australian Citizenship Status
                        </InputLabel>
                        <Select
                            labelId="auCitizenshipStatus-label"
                            name="auCitizenshipStatus"
                            value={profileDetails.auCitizenshipStatus}
                            label="Australian Citizenship Status"
                            error={requiredFields.auCitizenshipStatus}
                            onChange={handleProfileDetailsChange}
                        >
                            {auCitizenshipStatusDropdown.map((data) => (
                                <MenuItem key={data.key} value={data.value}>
                                    {data.value}
                                </MenuItem>
                            ))}
                        </Select>
                        {requiredFields.auCitizenshipStatus && (
                            <FormHelperText error={true} sx={{ fontSize: '0.875rem' }}>
                                {HELPER_TEXT}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="nationalities-label">Nationalities</InputLabel>
                        <Select
                            labelId="nationalities-label"
                            name="nationality"
                            multiple
                            value={profileDetails.nationality}
                            onChange={handleProfileDetailsChange}
                            input={<OutlinedInput label="Nationalities" />}
                            renderValue={(selected) => capitalize(selected.join(', '))}
                            MenuProps={MenuProps}
                        >
                            {nationailityDropdown.map((data) => (
                                <MenuItem key={data} value={data} sx={{ height: 36 }}>
                                    <Checkbox checked={(profileDetails.nationality || []).indexOf(data) > -1} />
                                    <ListItemText primary={capitalize(data)} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                    <TextField
                        label="LinkedIn URL"
                        onChange={handleProfileDetailsChange}
                        name="linkedInUrl"
                        value={profileDetails.linkedInUrl}
                        fullWidth
                        placeholder="Copy and paste the full URL to your LinkedIn Profile"
                    />
                </Grid>
            </Grid>
            {/* Row 4 */}
            <Grid
                sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                container
                spacing={2}
                padding={1}
                alignContent="space-around"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid xs={12} px={2} mt={2}>
                    <Typography>Current Location</Typography>
                </Grid>
                <Grid item sm={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id="country-label">Country</InputLabel>
                        <Select
                            labelId="country-label"
                            name="location.country"
                            value={profileDetails.location?.country}
                            label="Country"
                            onChange={handleProfileDetailsChange}
                        >
                            {countryDropdown.map((data) => (
                                <MenuItem key={data} value={data}>
                                    {data}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={12} md={3}>
                    <TextField label="City" onChange={handleProfileDetailsChange} name="location.city" value={profileDetails.location?.city} fullWidth />
                </Grid>
                <Grid item sm={12} md={3}>
                    <TextField label="State" onChange={handleProfileDetailsChange} name="location.state" value={profileDetails.location?.state} fullWidth />
                </Grid>
                <Grid item sm={12} md={3}>
                    <TextField
                        label="Postcode"
                        onChange={handleProfileDetailsChange}
                        name="location.postcode"
                        value={profileDetails.location?.postcode}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <br />
            <Divider></Divider>
            <br />
            {/* Row 5 */}
            <Grid
                sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                container
                spacing={2}
                padding={1}
                alignContent="space-around"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item sm={12}>
                    <Typography variant="h5">Career Summary & Aspirations</Typography>
                </Grid>
                <Grid item sm={12}>
                    <TextField
                        name="careerSummary"
                        value={profileDetails.careerSummary}
                        onChange={handleProfileDetailsChange}
                        label="Career Summary & Aspirations"
                        placeholder="We recommend one paragraph on what you have done, and one pararaph on what you would like to do."
                        rows={3}
                        inputProps={{ maxLength: 1000 }}
                        fullWidth
                        multiline
                    />
                </Grid>
                <Grid item sm />
            </Grid>
            <br />
            <Divider></Divider>
            <br />
            {/* Row 6 */}
            <Grid
                sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                container
                spacing={2}
                padding={1}
                alignContent="space-around"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item sm={12} md={6}>
                    <Typography variant="h5" mb={'1rem'}>
                        Working arrangements
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            labelId="working-arrangement"
                            name="workingArrangement"
                            value={profileDetails.workingArrangement || []}
                            onChange={handleProfileDetailsChange}
                            input={<OutlinedInput />}
                            multiple
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <Typography variant="grayText">List all the working arrangements you would be prepared to consider</Typography>;
                                }
                                return selected.join(', ');
                            }}
                            displayEmpty
                        >
                            {workingArrangementDropdown.map((item) => (
                                <MenuItem key={item.key} value={item.value} sx={{ height: 36 }}>
                                    <Checkbox checked={(profileDetails.workingArrangement || []).indexOf(item.value) > -1} />
                                    <ListItemText primary={item.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Typography variant="h5" mb={'1rem'}>
                        Employment Models
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            labelId="seeking-label"
                            name="seeking"
                            value={profileDetails.seeking}
                            onChange={handleProfileDetailsChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <Typography variant="grayText">List all of the employment models you would be prepared to consider</Typography>;
                                }
                                return selected.join(', ');
                            }}
                            MenuProps={MenuProps}
                            multiple
                            displayEmpty
                        >
                            <MenuItem disabled value="">
                                <Typography variant="grayText">List all of the employment models you would be prepared to consider</Typography>
                            </MenuItem>
                            {seekingDropdown.map((value, key) => (
                                <MenuItem key={key} value={value} sx={{ height: 36 }}>
                                    <Checkbox checked={(profileDetails.seeking || []).indexOf(value) > -1} />
                                    <ListItemText primary={value} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm />
            </Grid>
            <br />
            <Divider></Divider>
            <br />
            {/* Row 7 */}
            <Grid
                sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                container
                spacing={2}
                padding={1}
                alignContent="space-around"
                justifyContent="flex-start"
                alignItems="center"
            >
                {/* Row 7.1 */}
                <Grid item sm={12}>
                    <Typography variant="h5" ref={statusRef}>
                        Readiness
                    </Typography>
                    <Typography variant="body2">How quickly can you move?</Typography>
                </Grid>

                {/* Row 7.2 */}
                <Grid item sm={12} md={3}>
                    <Typography variant="body1" sx={{ color: requiredFields.readiness ? '#d32f2f' : '' }}>
                        Jobseeking Status *
                    </Typography>
                </Grid>
                <Grid item sm={12} md={6}>
                    <RadioGroup
                        row
                        onChange={handleProfileDetailsChange}
                        name="readiness"
                        value={profileDetails.readiness}
                        sx={{ border: requiredFields.readiness ? '1px solid #d32f2f' : 'none', flexDirection: 'column' }}
                    >
                        {profileReadiness.map((record, idx) => {
                            const label = (
                                <Typography>
                                    <b>{record.label}</b>{' '}
                                    <em>
                                        ({record.sub} <b>{record.keyword}</b>)
                                    </em>
                                </Typography>
                            );
                            return (
                                <CustomTooltip key={idx} title={record.hover} placement="top">
                                    <FormControlLabel value={record.value} control={<Radio />} label={label} />
                                </CustomTooltip>
                            );
                        })}
                    </RadioGroup>
                    {requiredFields.readiness && (
                        <FormHelperText error={true} sx={{ fontSize: '0.875rem' }}>
                            {HELPER_TEXT}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item md={3} display={{ sm: 'none', md: 'block' }} />

                {/* Row 7.3 */}
                <Grid item sm={12} md={3}>
                    <Typography variant="body1">Availability</Typography>
                </Grid>
                <Grid item sm={12} md={6}>
                    <TextField
                        name="availabilityComment"
                        placeholder="Comment on when you could start, how many weeks notice you will need to provide your existing employer, outstanding leave plans etc."
                        value={profileDetails.availabilityComment}
                        onChange={handleProfileDetailsChange}
                        inputProps={{ maxLength: 250 }}
                        rows={4}
                        fullWidth
                        multiline
                    />
                </Grid>
                <Grid item md={3} display={{ sm: 'none', md: 'block' }} />

                {/* Row 7.4 */}
                <Grid item sm={12} md={3}>
                    <Typography variant="body1">Mobility</Typography>
                </Grid>
                <Grid item sm={12} md={6}>
                    <TextField
                        name="mobilityComment"
                        placeholder="Comment on your ability to travel or relocate for potential roles."
                        value={profileDetails.mobilityComment}
                        onChange={handleProfileDetailsChange}
                        inputProps={{ maxLength: 250 }}
                        rows={4}
                        fullWidth
                        multiline
                    />
                </Grid>
            </Grid>
            <br />
            <Divider></Divider>
            <br />
            {/* Row 8 */}
            <Grid
                sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                container
                spacing={2}
                padding={1}
                alignContent="space-around"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item sm={12} md={12} p={0}>
                    <Typography variant="h5" m={0}>
                        Security Clearances
                    </Typography>
                </Grid>
                <Grid item sm={12} md={3}>
                    <Typography variant="body1">Australian Security Clearances</Typography>
                </Grid>
                <Grid item sm={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="securityClearance-label">Australian Security Clearances</InputLabel>
                        <Select
                            labelId="securityClearance-label"
                            name="securityClearance"
                            value={Array.isArray(profileDetails.securityClearance) ? profileDetails.securityClearance : [profileDetails.securityClearance]}
                            onChange={handleProfileDetailsChange}
                            input={<OutlinedInput label="Australian Security Clearances" />}
                            renderValue={(selected) => {
                                if (Array.isArray(selected)) {
                                    return selected.map((value) => mapValueToLabel(securityClearanceDropdown, value as string)).join(', ');
                                }

                                return selected;
                            }}
                            MenuProps={MenuProps}
                            multiple
                        >
                            {securityClearanceDropdown.map((data, key) => (
                                <MenuItem key={key} value={data.value} sx={{ height: 36 }}>
                                    <Checkbox checked={(profileDetails.securityClearance || []).indexOf(data.value) > -1} />
                                    <ListItemText primary={data.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <Divider></Divider>
            <br />
            {/* Row 9 */}
            <Grid
                sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                container
                spacing={2}
                padding={1}
                alignContent="space-around"
                justifyContent="flex-start"
                alignItems="center"
            >
                {/* Row 9.1 */}
                <Grid item sm={12}>
                    <Typography variant="h5">Affirmative Action (Optional)</Typography>
                    <Typography variant="body2">Our search engine allows jobseekers from minority groups to be targeted, but not excluded..</Typography>
                </Grid>

                {/* Row 9.2 */}
                <Grid item sm={12} md={6}>
                    <Typography variant="body1">Gender</Typography>
                </Grid>
                <Grid item sm={12} md={6}>
                    <RadioGroup row name="gender" value={profileDetails.gender}>
                        <FormControlLabel value="MALE" control={<Radio onClick={handleClick} />} label="Male" />
                        <FormControlLabel value="FEMALE" control={<Radio onClick={handleClick} />} label="Female" />
                        {/* <FormControlLabel value="OTHER" control={<Radio onClick={handleClick} />} label="Other" /> */}
                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio onClick={handleClick} />} label="Prefer not to say" />
                    </RadioGroup>
                </Grid>

                {/* Row 9.3 */}
                <Grid item sm={12} md={6}>
                    <Typography variant="body1">I am a military veteran of Australia, UK, US, Canada or NZ?</Typography>
                </Grid>
                <Grid item sm={12} md={6}>
                    <RadioGroup row name="veteranStatus" value={profileDetails.veteranStatus}>
                        <FormControlLabel value="YES" control={<Radio onClick={handleClick} />} label="Yes" />
                        <FormControlLabel value="NO" control={<Radio onClick={handleClick} />} label="No" />
                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio onClick={handleClick} />} label="Prefer not to say" />
                    </RadioGroup>
                </Grid>

                {/* Row 9.4 */}
                <Grid item sm={12} md={6}>
                    <Typography variant="body1">I am a partner or family member of a serving ADF member?</Typography>
                </Grid>
                <Grid item sm={12} md={6}>
                    <RadioGroup row name="partnerOrFamily" value={profileDetails.partnerOrFamily}>
                        <FormControlLabel value="YES" control={<Radio onClick={handleClick} />} label="Yes" />
                        <FormControlLabel value="NO" control={<Radio onClick={handleClick} />} label="No" />
                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio onClick={handleClick} />} label="Prefer not to say" />
                    </RadioGroup>
                </Grid>

                {/* Row 9.5 */}
                <Grid item sm={12} md={6}>
                    <Typography variant="body1">I am returning to the workforce from a prolonged absence due to caring activities?</Typography>
                </Grid>
                <Grid item sm={12} md={6}>
                    <RadioGroup row name="returningToWorkforce" value={profileDetails.returningToWorkforce}>
                        <FormControlLabel value="YES" control={<Radio onClick={handleClick} />} label="Yes" />
                        <FormControlLabel value="NO" control={<Radio onClick={handleClick} />} label="No" />
                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio onClick={handleClick} />} label="Prefer not to say" />
                    </RadioGroup>
                </Grid>

                {/* Row 9.6 */}
                <Grid item sm={12} md={6}>
                    <Typography variant="body1">I am an Aboriginal or Torres Strait Islander?</Typography>
                </Grid>
                <Grid item sm={12} md={6}>
                    <RadioGroup row name="aboriginalOrTorresStrait" value={profileDetails.aboriginalOrTorresStrait}>
                        <FormControlLabel value="YES" control={<Radio onClick={handleClick} />} label="Yes" />
                        <FormControlLabel value="NO" control={<Radio onClick={handleClick} />} label="No" />
                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio onClick={handleClick} />} label="Prefer not to say" />
                    </RadioGroup>
                </Grid>

                {/* Row 9.7 */}
                <Grid item sm={12} md={6}>
                    <Typography variant="body1">I have a pre-existing condition that may require additional workplace support?</Typography>
                </Grid>
                <Grid item sm={12} md={6}>
                    <RadioGroup row name="preExistingConditions" value={profileDetails.preExistingConditions}>
                        <FormControlLabel value="YES" control={<Radio onClick={handleClick} />} label="Yes" />
                        <FormControlLabel value="NO" control={<Radio onClick={handleClick} />} label="No" />
                        <FormControlLabel value="PREFER_NOT_TO_SAY" control={<Radio onClick={handleClick} />} label="Prefer not to say" />
                    </RadioGroup>
                </Grid>
            </Grid>
            <br />
            <Divider></Divider>
            <br />
            {/* Row 10 */}
            <Grid
                sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                container
                spacing={2}
                padding={1}
                alignContent={profileDetails.wizardCompleted ? 'flex-end' : 'space-between'}
                justifyContent={profileDetails.wizardCompleted ? 'flex-end' : 'space-between'}
                alignItems="center"
            >
                {profileDetails.wizardCompleted ? (
                    <Grid item sm={1}>
                        <Button onClick={onSubmit} variant="contained">
                            Save
                        </Button>
                    </Grid>
                ) : (
                    <>
                        <Grid item sm={1}>
                            <Button onClick={onPrevious} variant="outlined">
                                Previous
                            </Button>
                        </Grid>
                        <Grid item sm={1}>
                            <Button onClick={onSubmit} variant="contained">
                                Next
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
            {/* Modal */}
            <CommonModal open={open} title="CONGRATULATIONS" buttonLabel="OK" handleClick={handleCongratsModal}>
                <>
                    <Typography variant="body2">
                        Your profile is now complete and appears in the “For Jobseekers” menu where you can update it at any time.
                    </Typography>
                    <br />
                    <Typography variant="body2">
                        {`Your profile will be automatically considered and ranked against all other active candidates when employers blend skills and experiences to search for their "ideal candidate". This ensures that if you have relevant skills and experience for a role you will definitely be seen and considered; and if your skills don't quite match you won't waste your time applying for roles you were unlikely to be successful for.`}
                    </Typography>
                    <br />
                    <Typography variant="body2">To improve your search rankings you should encode the full range of your skills and experience.</Typography>
                    <br />
                    <Typography variant="body2">
                        Please also remember to change your job seeking status to “Not currently seeking work” if you are no longer in the job market. Your
                        profile will remain hidden until you reset your status to an active setting.
                    </Typography>
                    <br />
                    <Typography variant="body2">
                        Lastly, check out current opportunities on our <Link href={'./jobs'}>jobs board</Link>. Apply directly from within the platform.
                    </Typography>
                    <br />
                    <Typography variant="body2">Good Luck from all at the AUKUS Jobs team!</Typography>
                </>
            </CommonModal>
        </>
    );
}

export default Profile;
